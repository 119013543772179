// HowToUse.js

import React from 'react';
import AppShell from '../../components/layout/AppShell';
import { ArrowUpCircle, CheckCircle, Info } from 'lucide-react';

const Feature = ({ title, imageSrc, description, steps, benefits, index }) => (
  <div className="mb-24 bg-white rounded-lg shadow-lg overflow-hidden border border-grey-200">
    <div className="bg-gradient-to-r from-grey-100 to-grey-100 p-6">
      <h3 className="text-2xl font-bold tracking-tight text-darkGrey mb-2">
        {index + 1}. {title}
      </h3>
    </div>
    <div className="p-6">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8 items-start">
        <div className="lg:col-span-5 mb-8 lg:mb-0">
          <div className="aspect-w-16 aspect-h-9 lg:aspect-h-16 overflow-hidden rounded-lg shadow-md">
            <img
              src={imageSrc}
              alt={title}
              className="object-cover object-center w-full h-full transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>
        <div className="lg:col-span-7">
          <p className="text-base leading-7 text-slateGrey mb-6">
            {description}
          </p>
          <div className="bg-grey-50 rounded-lg p-6 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-darkGrey flex items-center">
              <CheckCircle className="w-5 h-5 mr-2 text-green-600" />
              How it works:
            </h4>
            <ul className="space-y-2">
              {steps.map((step, stepIndex) => (
                <li key={stepIndex} className="flex items-start">
                  <span className="text-green-600 mr-2">•</span>
                  <span className="text-sm leading-6 text-slateGrey">{step}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-grey-50 rounded-lg p-6">
            <h4 className="text-lg font-semibold mb-2 text-darkGrey flex items-center">
              <Info className="w-5 h-5 mr-2 text-blue-600" />
              Benefits:
            </h4>
            <p className="text-sm leading-6 text-slateGrey">
              {benefits}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const features = [
  {
    title: "Smart CV Customisation",
    imageSrc: "HowToUseImagery/smart-cv-customisation.png",
    description: "This feature tailors your CV for each job application by matching your skills and experiences to the employer's requirements. The image provided is an example, and actual results are customised specifically to each job description.",
    steps: [
      "Upload your current CV and the job description",
      "The system analyses the job advertisement and identifies key requirements",
      "It generates a checklist of essential qualifications",
      "Your CV is automatically adjusted to highlight relevant skills and experiences",
      "The system evaluates how well your CV aligns with the job requirements"
    ],
    benefits: "This feature saves time, improves performance with applicant tracking systems, and presents you as an ideal candidate for the role."
  },
  {
    title: "Bulk CV Conversion",
    imageSrc: "HowToUseImagery/BulkCVUpload.png",
    description: "This feature processes multiple CVs simultaneously, aligning them with a single job description:",
    steps: [
      "Upload up to 10 CVs and one job description",
      "Set conversion preferences (e.g., CV length, section limits)",
      "Receive tailored CVs aligned with the job requirements"
    ],
    benefits: "This feature streamlines the hiring process, ensures consistent formatting across all CVs, and quickly aligns multiple candidates to a specific role."
  },
  {
    title: "CV Content Control",
    imageSrc: "HowToUseImagery/cv-content-control.png",
    description: "This feature allows you to control the amount of information in different CV sections:",
    steps: [
      "Experience Limit: Select the number of work experiences to include",
      "Project Limit: Set the number of projects to showcase",
      "Skill Limit: Control the number of skills listed"
    ],
    benefits: "This feature keeps your CV concise, helps tailor it for specific jobs, and ensures your most impressive achievements are highlighted."
  },
  {
    title: "CV Length Optimisation",
    imageSrc: "HowToUseImagery/cv-length-optimisation.png",
    description: "This feature adjusts the level of detail in your CV with three options:",
    steps: [
      "Concise: Highly condensed, focusing on essential information",
      "Regular: Balanced output with comprehensive overview",
      "Detailed: Extensive version with in-depth information"
    ],
    benefits: "This feature helps tailor your CV's length to different job application requirements or industry standards."
  },
  {
    title: "Professional Tone Adjustment",
    imageSrc: "HowToUseImagery/professional-tone-adjustment.png",
    description: "This feature allows you to choose between first-person and third-person perspective:",
    steps: [
      "First Person to Third Person: Converts \"I\" to \"He/She/The candidate\"",
      "Maintain Third Person: Keeps all references in third person"
    ],
    benefits: "This feature ensures a uniform writing style, presenting a professional image across industries and application scenarios."
  },
  {
    title: "Personal Branding",
    imageSrc: "HowToUseImagery/personal-branding.png",
    description: "This feature adds a personal touch to your CV:",
    steps: [
      "Upload a small image (e.g., company logo) to appear in the top right corner",
      "The image is automatically included in your converted Word document CVs",
      "This is an optional feature - you can choose not to use it"
    ],
    benefits: "This simple addition makes your CV more memorable and professionally branded."
  }
];

export default function HowItWorks() {
  return (
    <AppShell currentPage="How-It-Works" loginRequired={false}>
      <main className="bg-white py-24">
        <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center mb-10">
              <p className="text-base font-semibold leading-7 text-grey-600">Maximise Your CV's Potential</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">How PivotCV Works</h1>
              <p className="mt-6 text-lg leading-8 text-slateGrey">
                PivotCV offers powerful tools to create a polished, professional CV. This guide explains how to effectively use PivotCV to convert, customise, and optimise your CV, showcasing your unique skills and experiences.
              </p>
            </div>
            <div className="mt-4 mb-16 flex flex-col sm:flex-row justify-center gap-4">
              <a
                href="/scenarios"
                className="inline-block rounded-md bg-white px-6 py-2.5 text-base font-semibold text-primaryBlue border border-primaryBlue shadow-sm hover:bg-grey-50 transition-colours duration-300"
              >
                Explore Scenarios
              </a>
            </div>

            <h2 className="text-3xl font-bold tracking-tight text-darkGrey mb-12 text-center">Key Features</h2>

            {features.map((feature, index) => (
              <Feature key={index} {...feature} index={index} />
            ))}

            <div className="flex items-center justify-center mt-12">
              <a href="#top" className="text-sm font-semibold leading-6 text-grey-600 hover:text-grey-900 transition-colours duration-300 flex items-center">
                <ArrowUpCircle className="w-5 h-5 mr-2" />
                Back to top
              </a>
            </div>
          </div>
        </div>
      </main>
    </AppShell>
  );
}