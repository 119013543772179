import React, { useState, useRef, useEffect } from "react";
import { SendIcon, UserIcon, TrashIcon } from "lucide-react";
import { sendMessage, resetChat } from "../../services/ChatService";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import AppShell from "../../components/layout/AppShell";

const INITIAL_MESSAGE = {
    id: "initial",
    text: "I can answer any questions that you may have with PivotCV.",
    isUser: false,
};

const MIN_INPUT_LENGTH = 2;
const CLEAR_CHAT_DELAY = 300;

export default function ApplicationChat() {
    const [messages, setMessages] = useState([INITIAL_MESSAGE]);
    const [input, setInput] = useState("");
    const [error, setError] = useState("");
    const [isClearing, setIsClearing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [streamingMessage, setStreamingMessage] = useState("");

    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const resizeTextArea = () => {
            if (inputRef.current) {
                inputRef.current.style.height = 'auto';
                inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
            }
        };

        resizeTextArea();
        window.addEventListener('resize', resizeTextArea);
        return () => window.removeEventListener('resize', resizeTextArea);
    }, [input]);

    const handleSend = async () => {
        if (input.trim().length < MIN_INPUT_LENGTH) {
            setError(`Please enter a valid question (at least ${MIN_INPUT_LENGTH} characters).`);
            return;
        }

        setError("");
        addMessage(input, true);
        setInput("");
        setIsProcessing(true);
        setStreamingMessage("");

        try {
            const fullResponse = await sendMessage(input, (chunk) => {
                setStreamingMessage((prev) => prev + chunk);
            });

            if (fullResponse.trim() === '') {
                throw new Error('Empty response received');
            }

            addMessage(fullResponse, false);
        } catch (error) {
            console.error("Error calling chat service:", error);
            addMessage("I'm sorry, but I encountered an error. Please try again later.", false);
        } finally {
            setStreamingMessage("");
            setIsProcessing(false);
        }
    };

    const addMessage = (text, isUser) => {
        const newMessage = { id: Date.now().toString(), text, isUser };
        setMessages(prev => [...prev, newMessage]);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleClearChat = async () => {
        setIsClearing(true);
        setIsProcessing(true);
        try {
            await resetChat();
            setTimeout(() => {
                setMessages([INITIAL_MESSAGE]);
                setInput("");
                setError("");
                setIsClearing(false);
                setIsProcessing(false);
            }, CLEAR_CHAT_DELAY);
        } catch (error) {
            console.error("Error resetting chat:", error);
            setError("Failed to reset chat. Please try again.");
            setIsClearing(false);
            setIsProcessing(false);
        }
    };

    return (
        <AppShell currentPage="DEMO Chatbot" loginRequired={true}>
            <div className="flex min-h-screen items-center justify-center p-2 sm:p-4 md:p-6 lg:p-8" ref={chatContainerRef}>
                <div className="w-full max-w-3xl h-full rounded-lg bg-white shadow-lg flex flex-col">
                    <ChatHeader onClearChat={handleClearChat} />
                    <div className="flex-grow min-h-[50vh] overflow-hidden">
                        <ChatMessages
                            messages={messages}
                            isClearing={isClearing}
                            messagesEndRef={messagesEndRef}
                            chatContainerRef={chatContainerRef}
                            isProcessing={isProcessing}
                            streamingMessage={streamingMessage}
                        />
                    </div>
                    <ChatInput
                        input={input}
                        setInput={setInput}
                        error={error}
                        setError={setError}
                        handleSend={handleSend}
                        handleKeyPress={handleKeyPress}
                        inputRef={inputRef}
                        isProcessing={isProcessing}
                    />
                </div>
                <style jsx global>{`
                body {
                    margin: 0;
                    padding: 0;
                    /* overflow: hidden; */
                }
                @media (max-width: 640px) {
                    .chat-container {
                        font-size: 16px;
                    }
                }
            `}</style>
            </div>
        </AppShell>
    );
}

function ChatHeader({ onClearChat }) {
    return (
        <div className="flex-none border-b border-gray-200 bg-white px-4 py-3">
            <div className="flex items-center justify-between">
                <h1 className="text-lg font-semibold text-gray-900">Product Assistant</h1>
                <button
                    onClick={onClearChat}
                    className="rounded p-1 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    aria-label="Clear chat"
                >
                    <TrashIcon className="h-5 w-5" />
                </button>
            </div>
        </div>
    );
}

function ChatMessages({ messages, isClearing, messagesEndRef, chatContainerRef, isProcessing, streamingMessage }) {
    return (
        <div className="flex-1 overflow-y-auto p-4 scrollbar-hide">
            <div className={`transition-opacity duration-300 ${isClearing ? 'opacity-0' : 'opacity-100'}`}>
                {messages.map((message) => (
                    <MessageBubble key={message.id} message={message} />
                ))}
                {streamingMessage && (
                    <MessageBubble message={{ id: 'streaming', text: streamingMessage, isUser: false }} />
                )}
                {isProcessing && !streamingMessage && <ProcessingIndicator />}
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
}

function MessageBubble({ message }) {
    return (
        <div className={`mb-4 flex ${message.isUser ? "justify-end" : "justify-start"}`}>
            {!message.isUser && <AIIcon />}
            <div
                className={`rounded-lg px-3 py-2 sm:px-4 sm:py-2 max-w-[85%] sm:max-w-[80%] break-words ${message.isUser
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-800"
                    }`}
            >
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ node, children, ...props }) => <h1 className="text-2xl font-bold mt-4 mb-2" {...props}>{children}</h1>,
                        h2: ({ node, children, ...props }) => <h2 className="text-xl font-bold mt-3 mb-2" {...props}>{children}</h2>,
                        h3: ({ node, children, ...props }) => <h3 className="text-lg font-bold mt-3 mb-2" {...props}>{children}</h3>,
                        h4: ({ node, children, ...props }) => <h4 className="text-base font-bold mt-2 mb-1" {...props}>{children}</h4>,
                        h5: ({ node, children, ...props }) => <h5 className="text-sm font-bold mt-2 mb-1" {...props}>{children}</h5>,
                        h6: ({ node, children, ...props }) => <h6 className="text-xs font-bold mt-2 mb-1" {...props}>{children}</h6>,
                        a: ({ node, children, ...props }) => (
                            <a
                                className="text-blue-600 hover:underline"
                                target="_blank"
                                rel="noopener noreferrer"
                                {...props}
                            >
                                {children || props.href}
                            </a>
                        ),
                        code: ({ node, inline, className, children, ...props }) => (
                            <code
                                className={`${inline
                                    ? 'bg-gray-200 text-red-500 rounded px-1'
                                    : 'block bg-gray-800 text-white p-2 rounded my-2 whitespace-pre-wrap'
                                    }`}
                                {...props}
                            >
                                {children}
                            </code>
                        ),
                        ul: ({ node, children, ...props }) => <ul className="list-disc pl-4 space-y-1 my-2" {...props}>{children}</ul>,
                        ol: ({ node, children, ...props }) => <ol className="list-decimal pl-4 space-y-1 my-2" {...props}>{children}</ol>,
                        li: ({ node, children, ...props }) => <li className="mb-1" {...props}>{children}</li>,
                        p: ({ node, children, ...props }) => <p className="mb-2 whitespace-pre-wrap" {...props}>{children}</p>,
                        blockquote: ({ node, children, ...props }) => (
                            <blockquote className="border-l-4 border-gray-300 pl-4 italic my-2" {...props}>{children}</blockquote>
                        ),
                        pre: ({ node, children, ...props }) => <pre className="whitespace-pre-wrap" {...props}>{children}</pre>,
                        table: ({ node, children, ...props }) => (
                            <div className="overflow-x-auto my-2">
                                <table className="min-w-full divide-y divide-gray-200" {...props}>{children}</table>
                            </div>
                        ),
                        thead: ({ node, children, ...props }) => <thead className="bg-gray-50" {...props}>{children}</thead>,
                        th: ({ node, children, ...props }) => (
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                {...props}
                            >{children}</th>
                        ),
                        td: ({ node, children, ...props }) => <td className="px-6 py-4 whitespace-nowrap" {...props}>{children}</td>,
                    }}
                >
                    {message.text}
                </ReactMarkdown>
            </div>
            {message.isUser && <UserAvatar />}
        </div>
    );
}

function ProcessingIndicator() {
    return (
        <div className="flex justify-start mb-4">
            <AIIcon />
            <div className="bg-gray-100 text-gray-800 rounded-lg px-3 py-4 sm:px-4 sm:py-2">
                <div className="flex space-x-2">
                    <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
                    <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                    <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                </div>
            </div>
        </div>
    );
}

function AIIcon() {
    return (
        <div className="mr-2 text-blue-500 flex-shrink-0">
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
            </svg>
        </div>
    );
}

function UserAvatar() {
    return (
        <div className="ml-2 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-gray-300 text-gray-600">
            <UserIcon className="h-5 w-5" />
        </div>
    );
}

function ChatInput({ input, setInput, error, setError, handleSend, handleKeyPress, inputRef, isProcessing, isClearing }) {
    const isInputDisabled = isProcessing || isClearing;

    const handleInputChange = (e) => {
        if (!isInputDisabled) {
            setInput(e.target.value);
            setError("");
        }
    };

    const handleInputKeyPress = (e) => {
        if (!isInputDisabled) {
            handleKeyPress(e);
        } else {
            e.preventDefault();
        }
    };

    const handleSendClick = () => {
        if (!isInputDisabled) {
            handleSend();
        }
    };

    return (
        <div className="flex-none border-t border-gray-200 bg-white p-4">
            <div className="flex flex-col space-y-2">
                <div className="flex items-end space-x-2">
                    <div className="relative flex-1">
                        <textarea
                            ref={inputRef}
                            value={input}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            placeholder="Message PivotCV..."
                            className={`w-full resize-none rounded-lg border border-gray-300 py-2 pr-12 pl-4 focus:border-blue-500 focus:outline-none scrollbar-hide text-base sm:text-sm ${isInputDisabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                            style={{ minHeight: '50px', maxHeight: '120px', overflowY: 'auto' }}
                            rows={1}
                            disabled={isInputDisabled}
                        />
                        <button
                            onClick={handleSendClick}
                            disabled={input.trim().length < MIN_INPUT_LENGTH || isInputDisabled}
                            className="absolute right-2 top-0 bottom-1.5 my-auto flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                            style={{ height: '36px', width: '36px' }}
                            aria-label="Send message"
                        >
                            <SendIcon className="h-5 w-5" />
                        </button>
                    </div>
                </div>
                {error && <p className="text-sm text-red-500">{error}</p>}
            </div>
        </div>
    );
}