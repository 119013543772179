import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';

export default function AddUserPopup({ open, setOpen, onSave }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [organisation, setOrganisation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [creditsRemaining, setCreditsRemaining] = useState(0);
    const [subscriptionStatus, setSubscriptionStatus] = useState('inactive');
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (subscriptionStatus === 'active' && creditsRemaining === 0) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [subscriptionStatus, creditsRemaining]);

    const handleSave = async () => {
        if (isLoading) return;

        if (!name.trim() || !email.trim() || !password.trim() || !role.trim()) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        if (!isValidEmail(email.trim())) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        if (password.trim().length < 8) {
            setErrorMessage('Password must be at least 8 characters long.');
            return;
        }

        // Add this validation
        if (creditsRemaining > 0 && subscriptionStatus !== 'active') {
            setSubscriptionStatus('active');
        }

        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const newUser = {
                name: name.trim(),
                email: email.trim(),
                password: password.trim(),
                user_role: role,
                organisation: organisation.trim(),
                credits_remaining: creditsRemaining,
                subscription_status: subscriptionStatus,
            };

            await onSave(newUser);
            setName('');
            setEmail('');
            setPassword('');
            setRole('user');
            setOrganisation('');
            setCreditsRemaining(0);
            setSubscriptionStatus('inactive');
            setSuccessMessage('User created successfully!');
            setOpen(false);
        } catch (error) {
            console.error('Error creating user:', error);
            setErrorMessage('An error occurred while creating the user. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const isValidEmail = (email) => {
        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                {/* Backdrop */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-darkGrey">
                                            Add New User
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <div className="mb-6">
                                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Email
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="role" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Role
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        name="role"
                                                        id="role"
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="user">User</option>
                                                        <option value="admin">Admin</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="organisation" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Organisation
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="organisation"
                                                        id="organisation"
                                                        value={organisation}
                                                        onChange={(e) => setOrganisation(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="SubscriptionStatus" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Subscription Status
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        name="SubscriptionStatus"
                                                        id="SubscriptionStatus"
                                                        value={subscriptionStatus}
                                                        onChange={(e) => setSubscriptionStatus(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="inactive">inactive</option>
                                                        <option value="active">active</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={`mb-6 ${showWarning ? 'border-red-500 border' : ''}`}>
                                                <label htmlFor="creditsRemaining" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Credits Remaining
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="number"
                                                        name="creditsRemaining"
                                                        id="creditsRemaining"
                                                        value={creditsRemaining}
                                                        onChange={(e) => setCreditsRemaining(parseInt(e.target.value))}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                                {showWarning && (
                                                    <p className="mt-2 text-sm text-red-600">
                                                        Subscription Status is set to Active with no Credits remaining.
                                                    </p>
                                                )}
                                            </div>
                                            {isLoading && <Spinner />}
                                            {errorMessage && (
                                                <AlertPopup
                                                    message={errorMessage}
                                                    type="error"
                                                    onClose={() => setErrorMessage('')}
                                                    duration={2000}
                                                />
                                            )}
                                            {successMessage && (
                                                <AlertPopup
                                                    message={successMessage}
                                                    type="success"
                                                    onClose={() => setSuccessMessage('')}
                                                    duration={2000}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:mt-10 flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-primaryBlue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleSave}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={() => setOpen(false)}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}