import { axiosInstance } from './HelperService';

async function sendMessage(message) {
    try {
        const response = await axiosInstance.post('admin/chat/send', { message });
        return response.data.response;
    } catch (error) {
        console.error('Error sending chat message:', error);
        throw error;
    }
}

async function resetChat() {
    try {
        const response = await axiosInstance.post('admin/chat/reset');
        return response.data.message;
    } catch (error) {
        console.error('Error resetting chat:', error);
        throw error;
    }
}

export { sendMessage, resetChat };