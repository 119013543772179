import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { loginUser } from '../../services/userService';
import { UserContext } from '../../UserContext';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState({ show: false, message: '', type: 'success' });
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  // Get the previous page from location state, or default to '/Home'
  const from = location.state?.from?.pathname || '/Home';

  useEffect(() => {
    if (user) {
      setPopup({ show: true, message: 'You are already logged in.', type: 'info' });
      const timer = setTimeout(() => navigate(from), 1500);
      return () => clearTimeout(timer);
    }
  }, [user, navigate, from]);

  const sanitizeInput = (input) => input.replace(/[<>/]/g, '');
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      setPopup({ show: true, message: 'You are already logged in.', type: 'info' });
      return;
    }

    setIsLoading(true);
    try {
      const sanitizedEmail = sanitizeInput(formData.email);
      const sanitizedPassword = sanitizeInput(formData.password);

      if (!isValidEmail(sanitizedEmail)) {
        throw new Error('Invalid email format');
      }

      const response = await loginUser(sanitizedEmail, sanitizedPassword);
      
      if (response?.access_token && response?.refresh_token) {
        setPopup({ show: true, message: 'Login successful!', type: 'success' });
        setTimeout(() => navigate(from), 1800);
      } else {
        throw new Error('Login failed: Unexpected response from server');
      }
    } catch (error) {
      let errorMessage = 'Invalid Email or Password. Please try again.';
      if (error.response) {
        errorMessage = error.response.status === 401 ? 'Invalid email or password' :
                       error.response.status === 500 ? 'Internal server error. Please try again later.' :
                       error.response.data?.error || errorMessage;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setPopup({ show: true, message: errorMessage, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (user) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Already Logged In</h2>
          <p>You are already logged in. Redirecting to your previous page...</p>
        </div>
        {popup.show && (
          <AlertPopup
            message={popup.message}
            type={popup.type}
            onClose={() => setPopup(prev => ({ ...prev, show: false }))}
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <Helmet>
        <title>Login | AI-Powered CV Converter</title>
        <meta
          name="description"
          content="Log in to your account and access our free AI-powered CV Converter. Convert and tailor your resume effortlessly to stand out from the competition."
        />
        <meta
          name="keywords"
          content="login, sign in, CV converter, resume converter, free CV converter, free resume converter, AI-powered CV converter, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume"
        />
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <a href="/">
          <img
            className="mx-auto h-12 w-auto"
            src="/PivotCV-Icon-Dark.svg"
            alt="Your Company"
          />
        </a>
        <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-darkGrey">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-darkGrey">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-darkGrey">
                Password
              </label>
              <div className="text-sm">
                <a href="/reset-password" className="font-semibold text-primaryBlue hover:text-lightBlue">
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primaryBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : 'Sign in'}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a user?{' '}
          <a href="/register" className="font-semibold leading-6 text-primaryBlue hover:text-lightBlue">
            Get started
          </a>
        </p>
        <div className="mt-6 text-center">
          <a
            href="/"
            className="inline-flex items-center text-sm font-medium text-slateGrey hover:text-gray-800"
          >
            <svg className="-ml-1 mr-1.5 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Back to Home
          </a>
        </div>
      </div>

      {popup.show && (
        <AlertPopup
          message={popup.message}
          type={popup.type}
          onClose={() => setPopup(prev => ({ ...prev, show: false }))}
        />
      )}
    </div>
  );
};

export default Login;