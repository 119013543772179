import React from 'react';
import { Helmet } from 'react-helmet';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import AppShell from '../../components/layout/AppShell';

const scenarios = [
  {
    title: "Rapid Response Team Formation",
    description: "In crisis situations, quickly assemble teams with specific skill sets by converting and filtering CVs to match urgent operational requirements.",
    details: "Our application excels in time-sensitive situations where assembling the right team can make all the difference. By rapidly processing and analysing CVs, we can identify personnel with the exact skills needed for a specific crisis. This capability ensures that response teams are not just quickly formed, but are composed of the most suitable individuals for the task at hand, significantly enhancing the effectiveness of crisis management efforts."
  },
  {
    title: "Security Clearance Pre-screening",
    description: "Tailor CVs to highlight security clearance levels and relevant experience, streamlining the vetting process for classified projects.",
    details: "In the defence sector, security clearance is paramount. Our system automatically identifies and prominently displays security clearance information within CVs, allowing for quick assessment of candidates for classified projects. This feature dramatically reduces the time and effort required in the pre-screening process, ensuring that only appropriately cleared personnel are considered for sensitive positions, thus maintaining the highest levels of security compliance."
  },
  {
    title: "Cross-departmental Talent Allocation",
    description: "Easily identify and redeploy personnel across different defence departments by reformatting CVs to emphasise transferable skills and experiences.",
    details: "Our tool revolutionises how defence organisations manage their human resources. By reformatting CVs to highlight transferable skills, we enable seamless redeployment of personnel across various departments. This flexibility allows defence organisations to quickly adapt to changing needs, ensuring that talent is optimally utilised across the entire sector, improving overall operational efficiency and effectiveness."
  },
  {
    title: "Training Program Optimisation",
    description: "Use converted CVs to identify skill gaps across the workforce, helping to design targeted training programs and allocate resources effectively.",
    details: "By analysing converted CVs en masse, our system provides invaluable insights into the skill composition of the entire workforce. This bird's-eye view allows for the identification of skill gaps and emerging needs. Armed with this information, defence organisations can design highly targeted training programs, ensuring that resources are allocated where they're most needed, and that the workforce remains at the cutting edge of required competencies."
  },
  {
    title: "International Coalition Building",
    description: "Standardise CVs of multinational defence personnel to facilitate smoother integration and collaboration in joint operations or peacekeeping missions.",
    details: "In an era of increasing international cooperation, our application bridges the gap between different CV formats and standards across nations. By converting diverse CV formats into a standardised form, we facilitate easier comparison and integration of multinational defence personnel. This standardisation is crucial for joint operations and peacekeeping missions, where quick understanding of each member's capabilities is essential for mission success."
  },
  {
    title: "Veteran Transition Support",
    description: "Convert military CVs into civilian-friendly formats, assisting veterans in transitioning to defence contractor roles or related civilian positions.",
    details: "Transitioning from military to civilian life can be challenging, especially when it comes to translating military experience into terms understood in the civilian sector. Our application specialises in converting military CVs into formats that highlight transferable skills and experiences in civilian terms. This feature is invaluable for veterans seeking roles in defence contracting or related civilian positions, ensuring their skills and experiences are accurately represented and valued."
  },
  {
    title: "Specialised Task Force Assembly",
    description: "Quickly identify and assemble personnel with niche skills (e.g., cybersecurity, AI, unmanned systems) for rapidly evolving technological challenges.",
    details: "In the fast-paced world of defence technology, the ability to quickly assemble teams with cutting-edge skills is crucial. Our system excels at identifying personnel with niche, highly specialised skills such as cybersecurity experts, AI specialists, or unmanned systems operators. This capability allows defence organisations to rapidly form task forces to address emerging technological challenges, keeping them at the forefront of defence innovation."
  },
  {
    title: "Succession Planning",
    description: "Identify potential candidates for leadership roles by reformatting CVs to highlight leadership experience and strategic capabilities.",
    details: "Effective succession planning is vital for maintaining strong leadership in defence organisations. Our application reformats CVs to prominently display leadership experiences and strategic thinking capabilities. This feature allows organisations to easily identify and nurture potential future leaders, ensuring a pipeline of capable individuals ready to step into crucial leadership roles when needed."
  },
  {
    title: "Research and Development Team Formation",
    description: "Match researchers and engineers to specific defence R&D projects by highlighting relevant technical skills and security clearances.",
    details: "Innovation is key in defence, and forming the right R&D teams is crucial. Our system excels at matching researchers and engineers to specific defence R&D projects by precisely highlighting relevant technical skills and appropriate security clearances. This targeted approach ensures that R&D teams are composed of the most suitable experts, accelerating innovation and maintaining technological superiority in defence capabilities."
  },
  {
    title: "Disaster Response Coordination",
    description: "Rapidly identify and deploy personnel with specific skills (e.g., logistics, medical, engineering) for natural disaster response or humanitarian missions.",
    details: "In disaster response scenarios, time is of the essence. Our application can quickly identify personnel with critical skills needed for disaster response, such as logistics experts, medical professionals, and engineers. This rapid identification and deployment capability ensures that defence organisations can respond swiftly and effectively to natural disasters or humanitarian crises, maximising the impact of their assistance efforts."
  },
  {
    title: "Compliance and Audit Preparation",
    description: "Format CVs to clearly demonstrate compliance with specific defence sector regulations or standards, facilitating smoother audits and inspections.",
    details: "Regulatory compliance is a critical aspect of defence operations. Our system formats CVs to clearly highlight compliance with specific defence sector regulations and standards. This feature streamlines the audit and inspection processes by ensuring that all personnel documentation is readily available in the required format, demonstrating clear compliance and reducing the time and stress associated with audits."
  },
  {
    title: "Interagency Collaboration",
    description: "Standardise CV formats across different defence and intelligence agencies to improve personnel sharing and joint task force formation.",
    details: "Effective collaboration between different defence and intelligence agencies is crucial for national security. Our application standardises CV formats across various agencies, breaking down information silos and facilitating seamless personnel sharing. This standardisation is particularly valuable in forming joint task forces, where quick understanding of each member's capabilities across agency lines is essential for mission success."
  }
];

const features = [
  "Bulk CV Conversion",
  "Standardised Format Creation",
  "Job Description Tailoring",
  "Security Clearance Highlighting",
  "Skill Gap Analysis",
  "Cross-departmental Skill Mapping",
  "Multilingual Support",
  "Compliance Checking"
];

const Scenarios = () => {
  return (
    <AppShell currentPage="Scenarios" loginRequired={false}>
      <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
        <Helmet>
          <title>PivotCV | Practical Scenarios | Advanced CV Management for Defence</title>
          <meta
            name="description"
            content="Explore practical scenarios where PivotCV's advanced CV management system revolutionises personnel operations in the defence sector. From rapid response team formation to interagency collaboration, discover how our tool enhances operational efficiency and readiness."
          />
        </Helmet>
        <div className="relative isolate pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4A90E2] to-[#50E3C2] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          <div className="py-16 sm:py-24 lg:pb-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="py-16 sm:py-24 lg:pb-8 text-center">
                <h1 className="text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl mb-6">
                  Practical Scenarios: PivotCV in Action
                </h1>
                <p className="mt-2 text-xl leading-8 text-slateGrey max-w-3xl mx-auto">
                  Discover how our state-of-the-art CV Conversion and Tailoring Application revolutionises personnel management in the defence sector. Explore real-world scenarios where PivotCV enhances efficiency, security, and operational readiness.
                </p>
              </div>
              <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
                  <a
                    href="/how-it-works"
                    className="inline-block rounded-md bg-white px-6 py-2.5 text-base font-semibold text-primaryBlue border border-primaryBlue shadow-sm hover:bg-gray-50 transition-colors duration-300"
                  >
                    How It Works
                  </a>
                </div>

              <div className="mt-16 sm:mt-24 lg:mt-32">
                {scenarios.map((scenario, index) => (
                  <div key={index} className="mb-16 bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-bold text-darkGrey mb-4">{scenario.title}</h2>
                    <p className="text-lg font-semibold text-slateGrey mb-4">{scenario.description}</p>
                    <p className="text-base text-slateGrey">{scenario.details}</p>
                  </div>
                ))}
              </div>

              <div className="mt-32 sm:mt-40 lg:mt-48 bg-gray-50 rounded-lg shadow-lg p-12">
                <h2 className="text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl text-center mb-8">
                  Key Features Enabling These Scenarios
                </h2>
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature, index) => (
                    <li key={index} className="flex items-center bg-white p-6 rounded-lg shadow-sm">
                      <CheckCircleIcon className="h-6 w-6 text-primaryBlue mr-4 flex-shrink-0" />
                      <span className="text-base text-slateGrey">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-32 sm:mt-40 lg:mt-48 text-center">
                <p className="text-xl text-slateGrey mb-8">
                  Ready to revolutionise your defence personnel management?
                </p>
                <a
                  href="/contact"
                  className="inline-block rounded-md bg-primaryBlue px-8 py-4 text-lg font-semibold text-white shadow-md hover:bg-deepNavy transition-colors duration-300 hover:shadow-lg"
                >
                  Contact Us for Advanced Defence Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default Scenarios;