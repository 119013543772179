import React, { useState, useEffect } from 'react';
import { Dialog, Transition, DialogPanel, DialogTitle, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import RadioButtonGroup from './RadioButtonGroup';
import IconUpload from './IconUploadComponent';
import NumberInput from './NumberInput';

const OutputMode = [
  { id: 1, title: 'Strict', description: ' ' },
  { id: 2, title: 'Flexible', description: ' ' },
];

const OutputLength = [
  { id: 1, title: 'Concise', description: ' ' },
  { id: 2, title: 'Regular', description: ' ' },
  { id: 3, title: 'Detailed', description: ' ' },
];

const OutputPerspective = [
  { id: 1, title: 'First-Person', description: ' ' },
  { id: 2, title: 'Third-Person', description: ' ' },
];

console.log('Imported components:', { RadioButtonGroup, IconUpload, NumberInput, Dialog, Transition });

export default function Sidebar({ open, onClose, onOptionsChange, options, cvIcon, updateCvIcon }) {
  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const handleModeSelect = (option) => {
    const newOptions = { ...localOptions, mode: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handleLengthSelect = (option) => {
    const newOptions = { ...localOptions, length: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handlePerspectiveSelect = (option) => {
    const newOptions = { ...localOptions, perspective: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handleSectionLimitChange = (section, value) => {
    const newOptions = {
      ...localOptions,
      sectionLimits: { ...localOptions.sectionLimits, [section]: value }
    };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-semibold leading-6 text-gray-900">
                          CV Options
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 px-4 sm:px-6">
                      <div className="space-y-6">
                        <IconUpload icon={cvIcon} updateIcon={updateCvIcon} />
                        <RadioButtonGroup
                          options={OutputMode}
                          selectedOption={localOptions.mode}
                          onSelect={handleModeSelect}
                          name="output-mode"
                        />
                        <RadioButtonGroup
                          options={OutputLength}
                          selectedOption={localOptions.length}
                          onSelect={handleLengthSelect}
                          name="output-length"
                        />
                        <RadioButtonGroup
                          options={OutputPerspective}
                          selectedOption={localOptions.perspective}
                          onSelect={handlePerspectiveSelect}
                          name="output-perspective"
                        />
                        <div className="pt-4">
                          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Section Limits</h3>
                          <div className="space-y-4">
                            <NumberInput
                              label="Experience"
                              value={localOptions.sectionLimits.experience}
                              onChange={(value) => handleSectionLimitChange('experience', value)}
                              min={1}
                              max={10}
                            />
                            <NumberInput
                              label="Projects"
                              value={localOptions.sectionLimits.project}
                              onChange={(value) => handleSectionLimitChange('project', value)}
                              min={1}
                              max={10}
                            />
                            <NumberInput
                              label="Skills"
                              value={localOptions.sectionLimits.skill}
                              onChange={(value) => handleSectionLimitChange('skill', value)}
                              min={1}
                              max={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}