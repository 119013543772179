import React, { useState } from 'react';
import { Info, AlertCircle, HelpCircle } from 'lucide-react';

export default function Tooltip({ 
  message, 
  position = 'top', 
  bgColor = 'bg-gray-800', 
  textColor = 'text-white',
  icon = 'info',
  iconColor = 'text-blue-500',
  hoverIconColor = 'text-blue-700',
  showArrow = true,
  maxWidth = "w-64",
  children 
}) {
  const [isVisible, setIsVisible] = useState(false);

  const positions = {
    top: 'bottom-full mb-2 left-1/2 -translate-x-1/2',
    bottom: 'top-full mt-2 left-1/2 -translate-x-1/2',
    left: 'right-full mr-2 top-1/2 -translate-y-1/2',
    right: 'left-full ml-2 top-1/2 -translate-y-1/2'
  };

  const arrowPositions = {
    top: 'top-full left-1/2 -translate-x-1/2 border-t',
    bottom: 'bottom-full left-1/2 -translate-x-1/2 border-b',
    left: 'left-full top-1/2 -translate-y-1/2 border-l',
    right: 'right-full top-1/2 -translate-y-1/2 border-r'
  };

  const iconComponents = {
    info: Info,
    alert: AlertCircle,
    help: HelpCircle,
  };

  const IconComponent = iconComponents[icon] || Info;

  return (
    <div 
      className="relative inline-flex items-center"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onFocus={() => setIsVisible(true)}
      onBlur={() => setIsVisible(false)}
    >
      <IconComponent 
        className={`h-5 w-5 ${iconColor} transition-colors duration-300 hover:${hoverIconColor} mr-1 cursor-help`} 
      />
      {children}
      <div
        className={`absolute ${positions[position]} ${maxWidth} transform rounded-lg ${bgColor} p-3 text-sm font-medium ${textColor} shadow-lg transition-all duration-300 ease-out ${
          isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
        } z-10`}
        style={{ pointerEvents: 'none' }}
      >
        <div className="relative">
          {showArrow && (
            <div 
              className={`absolute ${arrowPositions[position]} border-8 border-transparent ${bgColor.replace('bg-', 'border-')}`}
            ></div>
          )}
          <div className="whitespace-pre-wrap">{message}</div>
        </div>
      </div>
    </div>
  );
}