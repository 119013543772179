import React from 'react';
import AppShell from '../../components/layout/AppShell';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import {
  ShieldCheckIcon,
  LockClosedIcon,
  UserCircleIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

const faqs = [
  {
    id: 1,
    question: "How do we protect your data?",
    answer: "We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.",
  },
  {
    id: 2,
    question: "What information do we collect?",
    answer: "We collect information from you when you register on our site, place an order, subscribe to our newsletter, or fill out a form.",
  },
  {
    id: 3,
    question: "Do we use cookies?",
    answer: "Yes, we use cookies to enhance your experience, gather general visitor information, and track visits to our website.",
  },
  {
    id: 4,
    question: "How can you manage your data?",
    answer: "You can update or delete your data at any time by logging into your account and accessing your profile settings.",
  }
];

const featuresSummary = [
  {
    name: 'Data Encryption',
    description: 'We use advanced encryption techniques to ensure your data is protected both during transmission and storage.',
    icon: LockClosedIcon,
    href: '#FeatureDetail_1',
  },
  {
    name: 'User Control',
    description: 'You have full control over your personal information, including access to update, delete, or restrict the processing of your data.',
    icon: UserCircleIcon,
    href: '#FeatureDetail_2',
  },
  {
    name: 'Transparent Policies',
    description: 'Our data handling processes are transparent, and we provide clear explanations on how your information is used.',
    icon: DocumentTextIcon,
    href: '#FeatureDetail_3',
  },
  {
    name: 'Third-Party Compliance',
    description: 'We ensure that any third parties we engage with adhere to strict data privacy standards.',
    icon: ShieldCheckIcon,
    href: '#FeatureDetail_4',
  }
];

const FeatureCard = ({ feature }) => (
  <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center mb-4">
      <feature.icon className="h-8 w-8 text-primaryBlue mr-3" aria-hidden="true" />
      <h3 className="text-xl font-semibold text-darkGrey">{feature.name}</h3>
    </div>
    <p className="text-slateGrey mb-4">{feature.description}</p>
    <a href={feature.href} className="text-primaryBlue font-semibold hover:underline">
      Learn more →
    </a>
  </div>
);

const FeatureDetail = ({ id, title, subtitle, description }) => (
  <div id={id} className="bg-white py-16 sm:py-24">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-primaryBlue">{subtitle}</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">{title}</p>
        <p className="mt-6 text-lg leading-8 text-slateGrey">{description}</p>
      </div>
    </div>
  </div>
);

const DataPrivacy = () => {
  return (
    <AppShell currentPage="Data Privacy" loginRequired={false}>
      <Helmet>
        <title>YourApp | Data Privacy | Protecting Your Information</title>
        <meta
          name="description"
          content="Learn how we protect your data and ensure your privacy. Our comprehensive data privacy policies are designed to give you control and transparency over your personal information."
        />
        <meta
          name="keywords"
          content="data privacy, personal information protection, user data control, transparent data policies, data encryption, third-party compliance"
        />
      </Helmet>

      <main>
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#89CFF0] to-[#A5DEE5] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div id="top" className="min-h-[calc(100vh-25vh)] py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <p className="text-base font-semibold leading-7 text-primaryBlue">Your Privacy Matters</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">Protecting Your Data with Integrity</h1>
              <p className="mt-6 text-lg leading-8 text-slateGrey">
                We prioritize the safety and privacy of your personal information. Explore our data privacy policies to understand how we safeguard your data.
              </p>
            </div>

            <div className="mx-auto mt-16 max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">Our Commitment to Data Privacy</h2>
              <p className="mt-6 text-lg leading-8 text-slateGrey">
                We believe in full transparency and give you control over your personal data. Discover how our practices align with global data privacy standards.
              </p>
            </div>

            <div className="mx-auto mt-16 max-w-7xl">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                {featuresSummary.map((feature) => (
                  <FeatureCard key={feature.name} feature={feature} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {featuresSummary.map((feature, index) => (
          <FeatureDetail
            key={feature.name}
            id={`FeatureDetail_${index + 1}`}
            title={feature.name}
            subtitle={feature.name}
            description={feature.description}
          />
        ))}

        <div className="flex items-center justify-center py-8">
          <a href="#top" className="text-sm font-semibold leading-6 text-darkGrey hover:text-primaryBlue transition-colors">
            Back to top →
          </a>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
            <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-darkGrey">Frequently asked questions</h2>
              <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-darkGrey">
                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              ) : (
                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base leading-7 text-slateGrey">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </main>
    </AppShell>
  );
};

export default DataPrivacy;