// JSONService.js
import { axiosInstance } from './HelperService';

async function initiateBulkCVConversion(
  files,
  RFQFile = null,
  mode = 'Strict',
  perspective = 'FirstPerson',
  length = 'Regular',
  experienceLimit = 0,
  projectLimit = 0,
  skillLimit = 0,
  template = 'Generic'
) {
  try {
    if (!files || files.length === 0) {
      throw new Error('No CV files uploaded.');
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    if (RFQFile) {
      formData.append('requirement', RFQFile);
    }

    formData.append('mode', mode);
    formData.append('perspective', perspective);
    formData.append('length', length);
    formData.append('experience_limit', experienceLimit);
    formData.append('project_limit', projectLimit);
    formData.append('skill_limit', skillLimit);
    formData.append('template', template);

    const response = await axiosInstance.post('/convert/generate-resume-bulk', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  } catch (error) {
    console.error('Error initiating bulk CV conversion:', error);
    throw error;
  }
}

async function getCVConversionResults(taskId) {
  try {
    const response = await axiosInstance.get(`/convert/task-status/${taskId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching CV conversion results:', error);
    throw error;
  }
}

function base64ToBlob(base64, mime) {
  const byteChars = atob(base64);
  const byteNumbers = new Array(byteChars.length);
  for (let i = 0; i < byteChars.length; i++) {
    byteNumbers[i] = byteChars.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}

async function downloadConvertedResumes(taskId, iconData, iconMimeType, iconName, template) {
  try {
    const formData = new FormData();
    formData.append('task_id', taskId);
    formData.append('template', template);

    if (iconData && iconMimeType && iconName) {
      // Extract base64 data from Data URL
      const base64Match = iconData.match(/^data:(.*?);base64,(.*)$/);
      if (base64Match) {
        const base64 = base64Match[2];
        const iconBlob = base64ToBlob(base64, iconMimeType);
        formData.append('icon_image', iconBlob, iconName);
      } else {
        console.error('Invalid iconData format');
      }
    }

    const response = await axiosInstance.post(`/convert/generate-docx/${taskId}`, formData, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Get content type and disposition
    const contentType = response.headers['content-type'];
    const contentDisposition = response.headers['content-disposition'];

    // Determine filename
    let filename = 'ConvertedCV.docx';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }

    // Determine if it's a zip file
    const isZip = contentType === 'application/zip';

    if (isZip) {
      filename = 'resumes.zip';
    }

    console.log('Response Content-Type:', contentType);
    console.log('Response Content-Disposition:', contentDisposition);
    console.log('Determined filename:', filename);

    return {
      data: response.data,
      filename,
      isZip,
    };
  } catch (error) {
    console.error('Error downloading converted resumes:', error);
    throw error;
  }
}

export { initiateBulkCVConversion, getCVConversionResults, downloadConvertedResumes };
