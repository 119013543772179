import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../UserContext';
import { PhotoIcon } from '@heroicons/react/24/solid';
import AppShell from '../../components/layout/AppShell';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { updateUserProfile, uploadUserIcon, clearUserIcon } from '../../services/userService';
import { createPortalSession } from '../../services/subscriptionService';
import { Helmet } from 'react-helmet';

function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') {
        // Convert to string if possible, otherwise return empty string
        return typeof string === 'number' ? string.toString().charAt(0).toUpperCase() + string.toString().slice(1) : '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Profile = () => {
    const { user, refreshUserData } = useContext(UserContext);
    const [formData, setFormData] = useState({
        name: '',
        organisation: '',
        email: '',
        subscription_plan: '',
        subscription_status: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    useEffect(() => {
        if (user) {
            setFormData({
                name: user.name,
                organisation: user.organisation,
                email: user.email,
                subscription_plan: user.subscription_name,
                subscription_status: user.subscription_status,
            });
            if (user.icon_data) {
                // If icon_data is available, prepare it for display
                setSelectedFile({
                    preview: `data:${user.icon_mimetype};base64,${user.icon_data}`,
                    name: user.icon_filename || "Uploaded Icon"
                });
            } else if (user.icon_url) {
                // Fallback to icon_url if icon_data isn't available
                setSelectedFile({
                    preview: user.icon_url,
                    name: user.icon_filename || "Uploaded Icon"
                });
            }
            setLoading(false);
        } else {
            setError('Failed to load user profile. Please try again.');
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedFormats = ['image/png', 'image/jpeg', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB

        if (file && allowedFormats.includes(file.type) && file.size <= maxSize) {
            setSelectedFile(file);
            setUploadStatus('success');
        } else {
            setSelectedFile(null);
            setUploadStatus('error');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        setUploadStatus('success');
        setIsDraggingOver(false);
    };

    const handleUploadError = () => {
        setUploadStatus('error');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const profileData = {
            name: formData.name.trim(),
            organisation: formData.organisation.trim(),
            email: formData.email, // Assuming email is editable, if not remove this line
        };

        // Check if data has changed to decide if a server request is necessary
        const isProfileDataChanged = profileData.name !== user.name ||
            profileData.organisation !== user.organisation ||
            profileData.email !== user.email; // Check against existing user details

        try {
            let alertMessage = '';
            if (isProfileDataChanged) {
                await updateUserProfile(profileData);
                alertMessage = 'Profile updated successfully. ';
            }

            if (selectedFile && uploadStatus === 'success') {
                await uploadUserIcon(selectedFile);
                alertMessage += 'Icon uploaded successfully. ';
                setSelectedFile(null);
                setUploadStatus(null);
            }

            if (alertMessage) {
                setAlertMessage(alertMessage.trim());
                setAlertType('success');
                setShowAlert(true);
                refreshUserData(); // Refresh user data from the server to ensure UI is consistent with the backend
            } else {
                setAlertMessage('No changes made to the profile');
                setAlertType('info');
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setAlertMessage('Error updating profile. Please try again.');
            setAlertType('error');
            setShowAlert(true);
        }

        setLoading(false);
    };


    const handleClearIcon = async () => {
        try {
            await clearUserIcon(); // Function to clear the icon on the server
            setSelectedFile(null); // Clearing the state
            setUploadStatus(null);
            setAlertMessage('Icon removed successfully.');
            setAlertType('success');
            setShowAlert(true);
            refreshUserData(); // Optionally refresh user data from the server to reflect the change
        } catch (error) {
            console.error('Error clearing icon:', error);
            setAlertMessage('Failed to remove icon. Please try again.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const handleManageSubscription = async () => {
        try {
            const currentUrl = window.location.href;
            const portalUrl = await createPortalSession(currentUrl);
            window.location.href = portalUrl;
        } catch (error) {
            console.error('Error managing subscription:', error);
            setAlertMessage('Error managing subscription. Please try again.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const handleCancel = () => {
        // Resetting form data to the original user data loaded into the form
        setFormData({
            name: user.name || '',
            organisation: user.organisation || '',
            email: user.email || '',
            subscription_plan: user.subscription_name || '',
            subscription_status: user.subscription_status || '',
        });

        // Resetting the icon display based on the available data
        if (user.icon_data) {
            setSelectedFile({
                preview: `data:${user.icon_mimetype};base64,${user.icon_data}`,
                name: user.icon_filename || "Uploaded Icon"
            });
        } else if (user.icon_url) {
            setSelectedFile({
                preview: user.icon_url,
                name: user.icon_filename || "Uploaded Icon"
            });
        } else {
            setSelectedFile(null);
        }

        setUploadStatus(null); // Resetting upload status
        setError(null); // Clearing any existing errors

        // Showing an alert to inform the user that no changes have been saved
        setAlertMessage('No changes have been saved.');
        setAlertType('info');
        setShowAlert(true); // Display the alert popup
    };


    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <AlertPopup message={error} type="error" onClose={() => setError(null)} />;
    }

    return (
        <AppShell heading="Profile" currentPage="Profile" loginRequired={true}>
            <Helmet>
                <title>Your Profile</title>
            </Helmet>
            <div className='min-h-[calc(100vh-25vh)]'>
                <h2 className="text-2xl font-semibold mb-4">User Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mt-6 flex flex-row gap-4">
                        {user.subscription_status !== 'Inactive' ? (
                            <>
                                <div className="bg-gray-50 shadow-sm rounded-md p-6 mb-6 flex-1">
                                    <h3 className="text-lg text-slate-900 font-semibold mb-2">Subscription Status</h3>
                                    <p className="text-slate-800">{capitalizeFirstLetter(user.subscription_status)}</p>
                                    {user.subscription_plan !== 'free' && (
                                        <button
                                            type="button"
                                            className="mt-3 bg-gray-200 text-slateGrey px-4 py-1 text-sm rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                            onClick={handleManageSubscription}
                                        >
                                            Manage
                                        </button>
                                    )}
                                </div>
                                <div className="bg-gray-50 shadow-sm rounded-md p-6 mb-6 flex-1">
                                    <h3 className="text-lg text-slate-900 font-semibold mb-2">Subscription Plan</h3>
                                    <p className="text-slate-800">{capitalizeFirstLetter(user.subscription_name)}</p>
                                </div>
                                <div className="bg-gray-50 shadow-sm rounded-md p-6 mb-6 flex-1">
                                    <h4 className="text-lg text-slate-900 font-semibold mb-2">Credits Remaining</h4>
                                    <p className="text-slate-800">{user.credits_remaining}</p>
                                </div>
                            </>
                        ) : (
                            <div className="w-full flex justify-center">
                                <button
                                    type="button"
                                    className="mt-3 bg-red-500 text-white px-6 py-2 text-md rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                                    onClick={() => window.location.href = '/pricing'}
                                >
                                    View Pricing
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-slate-900">Profile</h2>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-slate-900">
                                        Icon Image
                                    </label>
                                    <div
                                        className={`mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed px-6 py-10 ${uploadStatus === 'success'
                                            ? 'border-green-500'
                                            : uploadStatus === 'error'
                                                ? 'border-red-500'
                                                : 'border-gray-900/25'
                                            } ${isDraggingOver ? 'bg-gray-100' : ''}`}
                                        onDragOver={handleDragOver}
                                        onDragEnter={() => setIsDraggingOver(true)}
                                        onDragLeave={() => setIsDraggingOver(false)}
                                        onDrop={handleDrop}
                                    >
                                        {selectedFile ? (
                                            <>
                                                <img
                                                    src={selectedFile.preview} // Use the data URL for the image src
                                                    alt="Uploaded Icon"
                                                    className="mx-auto h-12 w-auto"
                                                    onError={handleUploadError}
                                                />
                                                <p className="text-sm text-slateGrey mt-5">{selectedFile.name}</p>
                                                <button
                                                    type="button" // This should be 'button' to prevent form submission
                                                    className="bg-red-100 text-red-600 px-4 py-1 text-sm rounded-md hover:bg-red-200 mt-5"
                                                    onClick={handleClearIcon}
                                                >
                                                    Remove Icon
                                                </button>
                                            </>
                                        ) : (
                                            <div className="text-center">
                                                <PhotoIcon
                                                    className={`mx-auto h-12 w-12 text-slate-800 transition-transform duration-500 ease-in-out ${isDraggingOver ? 'scale-125' : ''}`}
                                                    aria-hidden="true"
                                                />
                                                <div className="mt-4 flex text-sm leading-6 text-slateGrey">
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer rounded-md font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-lightBlue"
                                                    >
                                                        <span>Upload a file</span>
                                                        <input
                                                            id="file-upload"
                                                            name="file-upload"
                                                            type="file"
                                                            className="sr-only"
                                                            onChange={handleFileChange}
                                                        />
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs leading-5 text-slateGrey">PNG, JPG up to 5MB</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-slate-900">Personal Information</h2>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-slate-900">
                                        Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder={user.name}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="organisation" className="block text-sm font-medium leading-6 text-slate-900">
                                        Organisation
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="organisation"
                                            id="organisation"
                                            value={formData.organisation}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder={user.organisation}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            readOnly
                                            className="block w-full rounded-md border-0 py-1.5 text-slate-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-800 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            className="text-sm font-semibold leading-6 text-slate-900"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-primaryBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
            {showAlert && (
                <AlertPopup
                    message={alertMessage}
                    type={alertType}
                    onClose={() => setShowAlert(false)}
                    duration={2000}
                />
            )}
        </AppShell>
    );
};

export default Profile;