import { useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { uploadDefaultCVIcon, removeDefaultCVIcon, getDefaultCVIcon } from '../../services/userService';
import AlertPopup from '../../components/layout/AlertPopUp';
import Spinner from '../../components/layout/Spinner';
import Tooltip from '../layout/Tooltip';

export default function IconUpload({ savedIcon, updateIcon }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);

  // Updated to match backend allowed formats
  const allowedFormats = ['image/png', 'image/jpeg', 'image/webp', 'image/gif', 'image/bmp', 'image/tiff'];
  const maxSize = 5 * 1024 * 1024; // 5MB

  const validateFile = (file) => {
    if (file && allowedFormats.includes(file.type) && file.size <= maxSize) {
      return true;
    }
    return false;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFile(file)) {
      setSelectedFile(file);
      setUploadStatus('success');
      setAlertMessage('Icon selected successfully.');
      setAlertType('success');
    } else {
      setSelectedFile(null);
      setUploadStatus('error');
      setAlertMessage('Invalid file format or size. Please select an image file up to 5MB.');
      setAlertType('error');
    }
    setShowAlert(true);
  };

  const handleDragEvents = (event) => {
    event.preventDefault();
    if (event.type === 'dragover') {
      setIsDraggingOver(true);
    } else if (event.type === 'dragleave') {
      setIsDraggingOver(false);
    } else if (event.type === 'drop') {
      setIsDraggingOver(false);
      const file = event.dataTransfer.files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
        setUploadStatus('success');
        setAlertMessage('Icon selected successfully.');
        setAlertType('success');
      } else {
        setSelectedFile(null);
        setUploadStatus('error');
        setAlertMessage('Invalid file format or size. Please select an image file up to 5MB.');
        setAlertType('error');
      }
      setShowAlert(true);
    }
  };

  const handleSaveIcon = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);
        await uploadDefaultCVIcon(selectedFile);
        const iconData = await getDefaultCVIcon();
        if (iconData) {
          const iconObject = {
            preview: `data:${iconData.icon_mimetype};base64,${iconData.icon_data}`,
            name: iconData.icon_filename,
          };
          updateIcon(iconObject);
        }
        setSelectedFile(null);
        setUploadStatus(null);
        setAlertMessage('Icon uploaded and saved successfully.');
        setAlertType('success');
      } catch (error) {
        console.error('Error uploading icon:', error);
        setAlertMessage('Failed to upload and save icon. Please try again.');
        setAlertType('error');
      } finally {
        setIsLoading(false);
      }
      setShowAlert(true);
    }
  };

  const handleRemoveIcon = async () => {
    try {
      setIsLoading(true);
      await removeDefaultCVIcon();
      updateIcon(null);
      setAlertMessage('Icon removed successfully.');
      setAlertType('success');
    } catch (error) {
      console.error('Error removing icon:', error);
      setAlertMessage('Failed to remove icon. Please try again.');
      setAlertType('error');
    } finally {
      setIsLoading(false);
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="col-span-full">
      <Tooltip message='This Icon will be used to brand your outputted CV.' position='right'>
        <label htmlFor="icon-upload" className="block text-sm font-medium leading-6 text-slate-900">
          Icon Image
        </label>
      </Tooltip>
      <div
        className={`mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed px-6 py-10 ${uploadStatus === 'success'
          ? 'border-green-500'
          : uploadStatus === 'error'
            ? 'border-red-500'
            : 'border-lightGrey'
          } ${isDraggingOver ? 'bg-gray-100' : ''}`}
        onDragOver={handleDragEvents}
        onDragEnter={handleDragEvents}
        onDragLeave={handleDragEvents}
        onDrop={handleDragEvents}
      >
        {isLoading ? (
          <Spinner />
        ) : selectedFile || savedIcon ? (
          <>
            <img
              src={selectedFile ? URL.createObjectURL(selectedFile) : savedIcon.preview}
              alt={selectedFile ? 'Dropped Icon' : 'Saved Icon'}
              className="mx-auto h-12 w-auto"
            />
            {selectedFile ? (
              <button
                type="button"
                className="mt-5 bg-primaryBlue text-white px-4 py-2 text-sm rounded-md hover:bg-indigo-700"
                onClick={handleSaveIcon}
              >
                Save Icon
              </button>
            ) : (
              <button
                type="button"
                className="mt-5 bg-red-100 text-red-600 px-4 py-1 text-sm rounded-md hover:bg-red-200"
                onClick={handleRemoveIcon}
              >
                Remove Icon
              </button>
            )}
          </>
        ) : (
          <>
            <div className="text-center">
              <PhotoIcon
                className={`mx-auto h-12 w-12 text-slate-800 transition-transform duration-500 ease-in-out ${isDraggingOver ? 'scale-125' : ''
                  }`}
                aria-hidden="true"
              />
              <div className="mt-4 flex text-sm leading-6 text-slateGrey">
                <label
                  htmlFor="file-upload"
                  className={`relative cursor-pointer rounded-md font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-lightBlue ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-slateGrey">
                PNG, JPG, JPEG, WebP, GIF, BMP, or TIFF up to 5MB
              </p>
            </div>
          </>
        )}
      </div>
      {showAlert && (
        <AlertPopup
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
          duration={3000}
        />
      )}
    </div>
  );
}