import { axiosInstance } from './HelperService';

const getTotalUsers = async () => {
    try {
        const response = await axiosInstance.get('/admin/total-users');
        return response.data.total_users;
    } catch (error) {
        throw error;
    }
};

const getTotalSubscribedUsers = async () => {
    try {
        const response = await axiosInstance.get('/admin/total-subscribed-users');
        return response.data.total_subscribed_users;
    } catch (error) {
        throw error;
    }
};

const getUsersTable = async (page = 1, perPage = 10) => {
    try {
        const response = await axiosInstance.get('/admin/users-table', {
            params: {
                page: page,
                per_page: perPage
            }
        });
        
        if (response.data && Array.isArray(response.data.users)) {
            return {
                users: response.data.users,
                total: response.data.total,
                page: response.data.page,
                per_page: response.data.per_page,
                total_pages: response.data.total_pages
            };
        } else {
            console.error('Unexpected response structure:', response.data);
            throw new Error('Unexpected response structure from server');
        }
    } catch (error) {
        console.error('Error fetching users table:', error);
        throw error;
    }
};

const updateUser = async (updatedUser) => {
    try {
        await axiosInstance.put(`/admin/user/${updatedUser.id}`, updatedUser);
    } catch (error) {
        throw error;
    }
};

const createUser = async (newUser) => {
    try {
        const response = await axiosInstance.post('/admin/user', newUser);
        return response.data.user;
    } catch (error) {
        throw error;
    }
};

const downloadCVData = async () => {
    try {
        const response = await axiosInstance.get('/admin/download-cv-data', {
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const generateDemoAccount = async (name, organisation) => {
    try {
        const response = await axiosInstance.post('/admin/create-demo-account', { name, organisation });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateUserTemplates = async (userId, templates) => {
    try {
        const response = await axiosInstance.put(`/admin/user/${userId}/templates`, { templates: templates });
        return response.data.user;
    } catch (error) {
        throw error;
    }
};

const deleteUser = async (userId) => {
    try {
        await axiosInstance.delete(`/admin/user/${userId}`);
    } catch (error) {
        throw error;
    }
};

export {
    getTotalUsers,
    getTotalSubscribedUsers,
    getUsersTable,
    updateUser,
    createUser,
    downloadCVData,
    generateDemoAccount,
    updateUserTemplates,
    deleteUser
};