import React, { useState, useEffect } from 'react';

export const TooltipProvider = ({ children }) => children;

export const Tooltip = ({ children, isSelectOpen }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isSelectOpen) {
            setIsVisible(false);
        }
    }, [isSelectOpen]);

    return (
        <div
            onMouseEnter={() => !isSelectOpen && setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {React.Children.map(children, child =>
                React.cloneElement(child, { isVisible })
            )}
        </div>
    );
};

export const TooltipTrigger = ({ asChild, children }) => children;

export const TooltipContent = ({ children, isVisible }) => (
    isVisible && (
        <div className="absolute z-10 px-2 py-1 text-sm bg-gray-700 text-white rounded shadow-lg">
            {children}
        </div>
    )
);