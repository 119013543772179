import React from 'react';
import PropTypes from 'prop-types';


const AlertPopup = ({ message, type, onClose, duration }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  const getAlertBgColor = () => {
    switch (type) {
      case 'success':
        return 'bg-green-100';
      case 'error':
        return 'bg-red-100';
      default:
        return 'bg-gray-100';
    }
  };

  const getAlertTextColor = () => {
    switch (type) {
      case 'success':
        return 'text-green-800';
      case 'error':
        return 'text-red-800';
      default:
        return 'text-gray-800';
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg ${getAlertBgColor()}`}>
      <div className="flex items-center">
        
        <span className={`text-sm font-medium ${getAlertTextColor()}`}>{message}</span>
        <button
          className="ml-auto text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={() => {
            setIsOpen(false);
            onClose();
          }}
        >
        </button>
      </div>
    </div>
  );
};

AlertPopup.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error']),
  onClose: PropTypes.func,
  duration: PropTypes.number,
};

AlertPopup.defaultProps = {
  type: 'success',
  onClose: () => {},
  duration: 3000,
};

export default AlertPopup;