import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import AppShell from '../../components/layout/AppShell';
import { getUserCreditsRemaining, getDefaultCVIcon } from '../../services/userService';
import Tooltip from '../../components/layout/Tooltip';
import { UserContext } from '../../UserContext';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Application/SidebarComponent';
import { zoomies } from 'ldrs';
import {
  initiateBulkCVConversion,
  getCVConversionResults,
  downloadConvertedResumes,
} from '../../services/JSONService';
import FileUpload from '../../components/ui/FileUpload';

zoomies.register();

const Application = () => {
  const { user, loading } = useContext(UserContext);

  const [isProcessing, setProcessing] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState('ConvertedCV.docx');
  const [popup, setPopup] = useState({ show: false, type: '', title: '', message: '' });

  const [resumeFile, setResumeFile] = useState(null);
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);

  const [creditsRemaining, setCreditsRemaining] = useState(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [cvIcon, setCvIcon] = useState(null);

  const [selectedTemplate, setSelectedTemplate] = useState('Generic');

  const [cvOptions, setCVOptions] = useState({
    mode: 'Strict',
    length: 'Regular',
    perspective: 'FirstPerson',
    sectionLimits: {
      experience: 5,
      project: 5,
      skill: 5,
    },
  });

  const pollingIntervalRef = useRef(null);

  const showPopup = useCallback((type, title, message) => {
    setPopup({ show: true, type, title, message });
  }, []);

  const hidePopup = useCallback(() => {
    setPopup({ show: false });
  }, []);

  const updateCreditsRemaining = useCallback(async () => {
    try {
      const response = await getUserCreditsRemaining();
      setCreditsRemaining(response.credits_remaining);
    } catch (error) {
      console.error('Failed to fetch credits remaining:', error);
    }
  }, []);

  useEffect(() => {
    updateCreditsRemaining();
  }, [updateCreditsRemaining]);

  useEffect(() => {
    const fetchDefaultCVIcon = async () => {
      try {
        const iconData = await getDefaultCVIcon();

        if (iconData && iconData.message !== 'No CV icon found for the user') {
          const iconObject = {
            preview: `data:${iconData.icon_mimetype};base64,${iconData.icon_data}`,
            name: iconData.icon_filename,
            type: iconData.icon_mimetype, // Ensure type is included for later use
          };
          setCvIcon(iconObject);
          localStorage.setItem('cvIcon', JSON.stringify(iconObject));
        } else {
          // Explicitly set cvIcon to null if no icon is found
          setCvIcon(null);
          localStorage.removeItem('cvIcon');
        }

      } catch (error) {
        console.error('Error obtaining user icon:', error);
        // Set cvIcon to null in case of an error
        setCvIcon(null);
        localStorage.removeItem('cvIcon');
      }
    };

    fetchDefaultCVIcon();
  }, []);

  const updateCvIcon = useCallback((newIcon) => {
    setCvIcon(newIcon);
    if (newIcon) {
      localStorage.setItem('cvIcon', JSON.stringify(newIcon));
    } else {
      localStorage.removeItem('cvIcon');
    }
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prev) => !prev);
  }, []);

  const handleOptionChange = useCallback((newOptions) => {
    setCVOptions(newOptions);
  }, []);

  const handleReset = useCallback(() => {
    setProcessing(false);
    if (downloadUrl) {
      window.URL.revokeObjectURL(downloadUrl);
    }
    setDownloadUrl(null);
    setDownloadFilename('ConvertedCV.docx');
    setResumeFile(null);
    setJobDescriptionFile(null);

    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, [downloadUrl]);

  const handleResumeFileUpload = useCallback((file) => {
    setResumeFile(file);
    return Promise.resolve();
  }, []);

  const handleJobDescriptionFileUpload = useCallback((file) => {
    setJobDescriptionFile(file);
    return Promise.resolve();
  }, []);

  const handleCompleteCVConversion = useCallback(async () => {
    if (!resumeFile) {
      showPopup('error', 'Error', 'Please upload a CV before converting.');
      return;
    }

    setProcessing(true);

    try {
      const data = await initiateBulkCVConversion(
        [resumeFile],
        jobDescriptionFile,
        cvOptions.mode,
        cvOptions.perspective,
        cvOptions.length,
        cvOptions.sectionLimits.experience,
        cvOptions.sectionLimits.project,
        cvOptions.sectionLimits.skill,
        selectedTemplate
      );

      const taskId = data.task_id;

      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }

      pollingIntervalRef.current = setInterval(async () => {
        try {
          const pollResult = await getCVConversionResults(taskId);

          if (pollResult.status === 'Completed') {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;

            const { data: downloadResponse, filename, isZip } = await downloadConvertedResumes(
              taskId,
              cvIcon?.preview || null,
              cvIcon?.type || null,
              cvIcon?.name || null,
              selectedTemplate
            );

            const downloadUrl = window.URL.createObjectURL(downloadResponse);
            await updateCreditsRemaining();
            setDownloadUrl(downloadUrl);
            setDownloadFilename(filename);
            setProcessing(false);
          } else if (pollResult.status === 'Failed') {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
            setProcessing(false);
            showPopup('error', 'Error', 'CV Conversion failed. Please try again.');
          }
        } catch (error) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
          setProcessing(false);
          showPopup('error', 'Error', 'An error occurred while fetching CV conversion results.');
        }
      }, 4000);

    } catch (error) {
      console.error('Error in handleCompleteCVConversion:', error);
      showPopup('error', 'Error', `Failed to complete action: ${error.message}`);
      setProcessing(false);
      await updateCreditsRemaining();
    }
  }, [
    resumeFile,
    jobDescriptionFile,
    cvOptions,
    selectedTemplate,
    cvIcon,
    updateCreditsRemaining,
    showPopup,
  ]);

  const handleDownload = useCallback(() => {
    if (downloadUrl && downloadFilename) {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = downloadFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadUrl, downloadFilename]);

  const renderTemplateDropdown = useCallback(() => {
    const allowedTemplates = ['Generic', ...(user?.allowed_templates || [])];

    if (allowedTemplates.length <= 1) return null;

    return (
      <div className="relative inline-block text-left">
        <select
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          className="block w-full pl-3 pr-10 py-2 text-sm border-lightGrey focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
          disabled={isProcessing || downloadUrl}
        >
          {allowedTemplates.map((template) => (
            <option key={template} value={template}>
              {template}
            </option>
          ))}
        </select>
      </div>
    );
  }, [user, selectedTemplate, isProcessing, downloadUrl]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AppShell currentPage="Application" loginRequired={true}>
      <Helmet>
        <title>CV Converter</title>
      </Helmet>

      <Sidebar
        open={sidebarOpen}
        onClose={toggleSidebar}
        options={cvOptions}
        onOptionsChange={handleOptionChange}
        cvIcon={cvIcon}
        updateCvIcon={updateCvIcon}
      />

      <div className=" min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className=" shadow-sm rounded-lg overflow-hidden">
            <div className="grid grid-cols-3 items-center p-6 border-b border-gray-200" dir="ltr">
              <div className="flex items-center">
                <div className="flex items-center p-2 bg-gray-100 rounded-md">
                  <Tooltip message="1 Credit is equal to 1 CV Conversion" position="right" />
                  <div className="relative inline-block">
                    <p className="ml-2 text-sm font-medium text-gray-900">
                      Credits Remaining: {creditsRemaining}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">{renderTemplateDropdown()}</div>

              <div className="flex justify-end">
                <Tooltip message="This will open the sidebar to customise your CV." position="left" />
                <button
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={toggleSidebar}
                  disabled={isProcessing || downloadUrl}
                >
                  Open Styling
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="relative" key={resumeFile ? `resume-${resumeFile.name}` : 'resume-reset'}>
                  <Tooltip
                    message="Here you upload your candidate's old CV / Resume."
                    position="right"
                  />
                  <FileUpload
                    id="resume-upload"
                    isResume={true}
                    onFileUpload={handleResumeFileUpload}
                    selectedFile={resumeFile}
                  />
                </div>
                <div className="relative" key={jobDescriptionFile ? `job-desc-${jobDescriptionFile.name}` : 'job-desc-reset'}>
                  <Tooltip message="This is where your Position description goes." position="right" />
                  <FileUpload
                    id="job-description-upload"
                    isResume={false}
                    onFileUpload={handleJobDescriptionFileUpload}
                    selectedFile={jobDescriptionFile}
                  />
                </div>
              </div>

              <div className="mt-8 flex flex-col items-center">
                {!downloadUrl && (
                  <button
                    onClick={handleCompleteCVConversion}
                    disabled={isProcessing}
                    className={`px-4 py-2 rounded-md text-white font-medium text-sm ${isProcessing
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      }`}
                  >
                    Convert CV
                  </button>
                )}
                {downloadUrl && (
                  <div className="flex space-x-4 mt-4">
                    <button
                      onClick={handleDownload}
                      className="px-4 py-2 rounded-md text-white font-medium text-sm bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Download
                    </button>
                    <button
                      onClick={handleReset}
                      className="px-4 py-2 rounded-md text-white font-medium text-sm bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Reset
                    </button>
                  </div>
                )}
                {isProcessing && (
                  <div className="w-full mt-6 flex flex-col items-center">
                    <l-zoomies
                      size="800"
                      stroke="6"
                      bg-opacity="0.1"
                      speed="2.1"
                      color="#4F46E5"
                    ></l-zoomies>
                    <p className="text-center text-sm font-medium text-gray-600 mt-2">
                      Processing...
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-8 flex items-center justify-center">
            <p className="text-xs leading-5 text-gray-500 text-center">
              This app is still being improved. As you continue to use the app, we would appreciate
              any feedback or bug reports emailed to
              <a
                href="mailto:Support@PivotCV.com.au"
                className="underline text-gray-500 ml-1 hover:text-gray-700"
              >
                Support@PivotCV.com.au
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      {popup.show && (
        <AlertPopup message={popup.message} type={popup.type} onClose={hidePopup} />
      )}
    </AppShell>
  );
};

export default Application;