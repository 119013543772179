import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { requestPasswordReset, resetPassword } from '../../services/userService';
import { UserContext } from '../../UserContext';
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [oneTimePassword, setOneTimePassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('success');
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [otpRequested, setOtpRequested] = useState(false);

    useEffect(() => {
        if (user) {
            navigate('/Home');
        }
    }, [user, navigate]);


    // Input sanitization function
    const sanitizeInput = (input) => {
        return input.replace(/[<>/]/g, '');
    };

    const validateForm = () => {
        const errors = {};

        if (!email.trim()) {
            errors.email = 'Please enter your email address';
        }

        if (otpRequested) {
            if (!oneTimePassword.trim()) {
                errors.oneTimePassword = 'Please enter the one-time password';
            }

            if (newPassword.length < 8) {
                errors.newPassword = 'New password must be at least 8 characters long';
            }

            if (newPassword !== confirmPassword) {
                errors.confirmPassword = 'New password and confirm password do not match';
            }
        }

        return Object.keys(errors).length === 0;
    };

    const handleRequestOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!email.trim()) {
            setPopupMessage('Please enter your email address');
            setPopupType('error');
            setShowPopup(true);
            setIsLoading(false);
            return;
        }

        try {
            const sanitizedEmail = sanitizeInput(email);
            await requestPasswordReset(sanitizedEmail);
            setPopupMessage('One-time password has been sent to your email');
            setPopupType('success');
            setShowPopup(true);
            setOtpRequested(true);
        } catch (error) {
            if (error.message === 'Too many requests. Please try again later.') {
                setPopupMessage('Too many attempts. Please try again later.');
            } else {
                setPopupMessage('Failed to request one-time password. Please try again.');
            }
            setPopupType('error');
            setShowPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!validateForm()) {
            setIsLoading(false);
            return;
        }

        try {
            const sanitizedEmail = sanitizeInput(email);
            const sanitizedOneTimePassword = sanitizeInput(oneTimePassword);
            const sanitizedNewPassword = sanitizeInput(newPassword);

            await resetPassword(sanitizedEmail, sanitizedOneTimePassword, sanitizedNewPassword);
            setPopupMessage('Password reset successful!');
            setPopupType('success');
            setShowPopup(true);
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 400) {
                    errorMessage = 'Invalid email or one-time password';
                } else if (error.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                }
            } else {
                errorMessage = error.message;
            }
            setPopupMessage(errorMessage);
            setPopupType('error');
            setShowPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <a href="/">
                    <img
                        className="mx-auto h-12 w-auto"
                        src="/PivotCV-Icon-Dark.svg"
                        alt="Your Company"
                    />
                </a>

                <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-darkGrey">
                    Reset your password
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {!otpRequested ? (
                    <form className="space-y-6" onSubmit={handleRequestOtp}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-darkGrey">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primaryBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                disabled={isLoading}
                            >
                                {isLoading ? <Spinner /> : 'Request One-Time Password'}
                            </button>
                        </div>
                    </form>
                ) : (
                    <form className="space-y-6" onSubmit={handleResetPassword}>
                        <div>
                            <label htmlFor="oneTimePassword" className="block text-sm font-medium leading-6 text-darkGrey">
                                One-Time Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="oneTimePassword"
                                    name="oneTimePassword"
                                    type="text"
                                    required
                                    value={oneTimePassword}
                                    onChange={(e) => setOneTimePassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-darkGrey">
                                New Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-darkGrey">
                                Confirm Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primaryBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                disabled={isLoading}
                            >
                                {isLoading ? <Spinner /> : 'Reset Password'}
                            </button>
                        </div>
                    </form>
                )}

                <div className="mt-6 text-center">
                    <a
                        href="/login"
                        className="inline-flex items-center text-sm font-medium text-slateGrey hover:text-gray-800"
                    >
                        <svg className="-ml-1 mr-1.5 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        Back to Login
                    </a>
                </div>
            </div>

            {showPopup && (
                <AlertPopup
                    message={popupMessage}
                    type={popupType}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default ResetPassword;