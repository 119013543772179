import React, { useEffect, useState, useContext } from 'react';
import AppShell from '../../components/layout/AppShell';
import { Helmet } from 'react-helmet';
import { UserContext } from '../../UserContext';
import {
  DocumentTextIcon,
  AdjustmentsHorizontalIcon,
  PencilSquareIcon,
  ScissorsIcon,
  UserCircleIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/20/solid';

const features = [
  {
    name: 'AI CV Converter',
    description: 'Aligns your old CV to the position description and generates a new, tailored CV.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Customisable Output',
    description: 'Personalise your CV’s format and content to suit your preferences.',
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: 'Spelling and Grammar Correction',
    description: 'Automatically corrects any errors to ensure your CV is polished and professional.',
    icon: PencilSquareIcon,
  },
  {
    name: 'Page Count Adjustment',
    description: 'Shortens lengthy CVs to fit a specified page count without losing important details.',
    icon: ScissorsIcon,
  },
  {
    name: 'Perspective Conversion',
    description: 'Converts your CV from 1st person to 3rd person to match industry standards.',
    icon: UserCircleIcon,
  },
  {
    name: 'Multi-job Customisation',
    description: 'Take your existing CV and customise it to match multiple job descriptions effortlessly.',
    icon: ClipboardDocumentListIcon,
  },
];

const Home = () => {
  const [videoEnded, setVideoEnded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    }
  }, [user]);

  useEffect(() => {
    const video = document.getElementById("demoVideo");
    const playButton = document.getElementById("playButton");
    const watchAgainButton = document.getElementById("watchAgainButton");
    const tryNowButton = document.getElementById("tryNowButton");
    const overlay = document.getElementById("overlay");

    const showOverlay = () => {
      overlay.classList.remove('hidden'); // Show overlay
      playButton.style.display = 'flex'; // Show play button initially
      watchAgainButton.style.display = 'none'; // Hide watch again button
      tryNowButton.style.display = 'none'; // Hide try now button
    };

    const showEndOverlay = () => {
      overlay.classList.remove('hidden'); // Show overlay
      playButton.style.display = 'none'; // Hide play button
      watchAgainButton.style.display = 'flex'; // Show watch again button
      tryNowButton.style.display = 'flex'; // Show try now button
    };

    const hideOverlay = () => {
      overlay.classList.add('hidden'); // Hide overlay
      playButton.style.display = 'none'; // Hide play button
    };

    showOverlay(); // Initially show overlay

    video.addEventListener('play', hideOverlay,);
    video.addEventListener('pause', showOverlay);
    video.addEventListener('ended', () => {
      setVideoEnded(true);
      showEndOverlay();
    });

    playButton.addEventListener('click', () => {
      setVideoEnded(false);
      video.play();
      hideOverlay();
    });

    watchAgainButton.addEventListener('click', () => {
      setVideoEnded(false);
      video.currentTime = 0; // Restart video
      video.play();
      hideOverlay();
    });

    tryNowButton.addEventListener('click', () => {
      window.location.href = '/Register'; // Redirect to Register page
    });

    return () => {
      video.removeEventListener('play', hideOverlay);
      video.removeEventListener('pause', showOverlay);
      video.removeEventListener('ended', null);
      playButton.removeEventListener('click', null);
      watchAgainButton.removeEventListener('click', null);
      tryNowButton.removeEventListener('click', null);
    };
  }, [videoEnded]);

  return (
    <AppShell currentPage="Home" loginRequired={false}>
      <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
        <Helmet>
          <title>PivotCV | CV Converter | AI-Powered Resume Conversion & Tailoring</title>
          <meta
            name="description"
            content="Revolutionise your job search with our free AI-powered CV Converter. Convert, personalise, and correct your resume in less than 30 seconds. Tailor your CV to specific job descriptions and create a professional CV from plain text effortlessly."
          />
          <meta
            name="keywords"
            content="CV converter, resume converter, free CV converter, free resume converter, AI-powered CV converter, AI-driven resume conversion, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume, CV builder, resume builder, CV template, resume template, job application, career boost, increase job prospects, land dream job"
          />
        </Helmet>
        <div className="relative isolate pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4A90E2] to-[#50E3C2] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          <div className="py-16 sm:py-16 lg:pb-16 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="py-16 sm:py-16 lg:pb-16 text-center">
                <h1 className="text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">
                  Focus your CV for Unlimited Opportunities
                </h1>
                <p className="mt-4 text-lg leading-8 text-slateGrey">
                  Transform your CV for every opportunity. PivotCV empowers you to craft tailored, professional resumes that align perfectly with each role's unique requirements. Say goodbye to generic applications and hello to standout submissions that showcase your true potential.
                </p>
                <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
                  {!isLoggedIn && (
                    <a
                      href="/Register"
                      className="inline-block rounded-md bg-primaryBlue px-6 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-deepNavy transition-colors duration-300"
                    >
                      Register Now
                    </a>
                  )}
                  <a
                    href="/how-it-works"
                    className="inline-block rounded-md bg-white px-6 py-2.5 text-base font-semibold text-primaryBlue border border-primaryBlue shadow-sm hover:bg-gray-50 transition-colors duration-300"
                  >
                    How It Works
                  </a>
                  <a
                    href="/scenarios"
                    className="inline-block rounded-md bg-white px-6 py-2.5 text-base font-semibold text-primaryBlue border border-primaryBlue shadow-sm hover:bg-gray-50 transition-colors duration-300"
                  >
                    Explore Scenarios
                  </a>
                </div>
              </div>

              {/* Features Section */}
              <div className="mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24">
                <dl className="grid grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-slateGrey sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-12">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative bg-white p-6 rounded-lg shadow-sm transition-transform hover:scale-105 hover:shadow-md">
                      <div className="flex items-start">
                        <feature.icon className="h-6 w-6 text-primaryBlue mr-4" aria-hidden="true" />
                        <div>
                          <dt className="font-semibold text-darkGrey">
                            {feature.name}
                          </dt>
                          <dd className="mt-1 text-slateGrey">
                            {feature.description}
                          </dd>
                        </div>
                      </div>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mb-20 mt-16 flex items-center justify-center gap-x-6">
                <a
                  href="/about"
                  className="inline-block rounded-md bg-primaryBlue px-6 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-deepNavy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryBlue transition-colors duration-300"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
              <div className="mt-14 sm:mt-14">
                <div className="relative rounded-xl overflow-hidden shadow-lg">
                  <video id="demoVideo" src="/PivotCV Demo Video.mp4" className="w-full" muted poster='/PivotCVDemoVideoPoster.png' />
                  <div id="overlay" className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-40 transition-opacity duration-300">
                    <button id="playButton" className="bg-white text-gray-900 px-6 py-3 rounded-md shadow-lg text-base font-semibold hover:bg-gray-100 transition-colors">
                      Discover the 3-Step CV Conversion Process
                    </button>
                    <button id="watchAgainButton" className="hidden bg-white text-gray-900 px-6 py-3 rounded-md shadow-lg text-base font-semibold hover:bg-gray-100 transition-colors mt-4">
                      Watch Again
                    </button>
                    <button id="tryNowButton" className="hidden bg-blue-600 text-white px-6 py-3 rounded-md shadow-lg text-base font-semibold hover:bg-blue-700 transition-colors mt-4">
                      Try Now
                    </button>
                  </div>
                </div>
              </div>

              {/* Corporate Solutions Section */}
              <section className="mt-20 py-16 bg-gray-50 rounded-lg shadow-md">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl mb-6">
                      Customised CV Layouts for Corporate Clients
                    </h2>
                    <p className="text-lg leading-8 text-slateGrey mb-8">
                      Elevate your company's recruitment process with bespoke CV layouts. We offer tailored solutions that align perfectly with your corporate branding and specific requirements.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                      <a
                        href="mailto:Support@PivotCV.com.au"
                        className="inline-block rounded-md bg-primaryBlue px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-deepNavy transition-colors duration-300"
                      >
                        Contact Us for Corporate Solutions
                      </a>
                      <a
                        href="/scenarios"
                        className="inline-block rounded-md bg-white px-6 py-3 text-base font-semibold text-primaryBlue border border-primaryBlue shadow-sm hover:bg-gray-50 transition-colors duration-300"
                      >
                        View Use Cases
                      </a>
                    </div>
                    <p className="text-xs text-gray-400 mt-1">Support@PivotCV.com.au</p>
                  </div>
                  <div id="corporate-benefits" className="mt-12">
                    <h3 className="text-2xl font-semibold text-darkGrey mb-6 text-center">Why Choose Our Corporate Solutions?</h3>
                    <ul className="space-y-4 text-slateGrey">
                      <li className="flex items-start">
                        <svg className="h-6 w-6 text-primaryBlue mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>Customized templates that reflect your brand identity</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="h-6 w-6 text-primaryBlue mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>Streamlined recruitment process with standardized CV formats</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="h-6 w-6 text-primaryBlue mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>Enhanced candidate experience with professional, branded CVs</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="h-6 w-6 text-primaryBlue mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>Dedicated support for seamless integration and ongoing assistance</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-8 text-center">
                    <p className="text-lg font-semibold text-primaryBlue">
                      Contact us to have your Corporate CV converted into a customisable template for use with PivotCV. This will allow you to easily format CVs according to your company's requirements.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default Home;