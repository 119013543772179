import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { updateUserTemplates } from '../../services/adminService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function EditUserPopup({ open, setOpen, user, onSave }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('user');
    const [subscription_status, setSubscriptionStatus] = useState('inactive');
    const [organisation, setOrganisation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [convertedCvsCount, setConvertedCvsCount] = useState(0);
    const [creditsRemaining, setCreditsRemaining] = useState(0);
    const [initialCreditsRemaining, setInitialCreditsRemaining] = useState(0);
    const [templates, setTemplates] = useState([]);
    const [newTemplate, setNewTemplate] = useState('');

    useEffect(() => {
        if (user) {
            setName(user.name || '');
            setEmail(user.email || '');
            setRole(user.user_role || 'user');
            setOrganisation(user.organisation || '');
            setConvertedCvsCount(user.converted_cvs_count || 0);
            setCreditsRemaining(user.credits_remaining || 0);
            setInitialCreditsRemaining(user.credits_remaining || 0); // Track initial credits
            setSubscriptionStatus(user.subscription_status || 'inactive');
            setTemplates(user.allowed_templates || []);
        }
    }, [user]);

    const handleAddTemplate = (e) => {
        e.preventDefault();
        if (newTemplate.trim() && !templates.includes(newTemplate.trim())) {
            setTemplates([...templates, newTemplate.trim()]);
            setNewTemplate('');
        }
    };

    const handleRemoveTemplate = (templateToRemove) => {
        setTemplates(templates.filter(template => template !== templateToRemove));
    };

    const handleSave = async () => {
        if (!name.trim() || !email.trim() || !role.trim()) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }
    
        if (creditsRemaining > 0) {
            setSubscriptionStatus('active');
        }
    
        setIsLoading(true);
    
        try {
            const updatedUser = {
                ...user,
                name: name.trim(),
                email: email.trim(),
                user_role: role,
                organisation: organisation.trim(),
                converted_cvs_count: convertedCvsCount,
                subscription_status: subscription_status,
            };
    
            // Include credits_remaining only if it has changed
            if (creditsRemaining !== initialCreditsRemaining) {
                updatedUser.credits_remaining = creditsRemaining;
            }
    
            // Update user details
            await axios.put(`${API_BASE_URL}/admin/user/${user.id}`, updatedUser, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
            });
    
            // Update user templates
            await updateUserTemplates(user.id, templates);
    
            // Fetch the updated user data
            const updatedUserData = await axios.get(`${API_BASE_URL}/admin/user/${user.id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
            });
    
            onSave(updatedUserData.data.user);
            setOpen(false);
        } catch (error) {
            console.error('Error updating user:', error);
            setErrorMessage('An error has occurred. Please try again.');
        }
    
        setIsLoading(false);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                {/* Backdrop */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-darkGrey">
                                            Edit User Details
                                        </Dialog.Title>
                                        <div className="mt-8">
                                            <div className="mb-6">
                                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Email
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="role" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Role
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        name="role"
                                                        id="role"
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="user">User</option>
                                                        <option value="admin">Admin</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="organisation" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Organisation
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="organisation"
                                                        id="organisation"
                                                        value={organisation}
                                                        onChange={(e) => setOrganisation(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="convertedCvsCount" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Converted CVs Count
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="number"
                                                        name="convertedCvsCount"
                                                        id="convertedCvsCount"
                                                        value={convertedCvsCount}
                                                        onChange={(e) => setConvertedCvsCount(parseInt(e.target.value))}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="subscription_status" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Subscription Status
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        name="subscription_status"
                                                        id="subscription_status"
                                                        value={subscription_status}
                                                        onChange={(e) => setSubscriptionStatus(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="inactive">inactive</option>
                                                        <option value="active">active</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="creditsRemaining" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Credits Remaining
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="number"
                                                        name="creditsRemaining"
                                                        id="creditsRemaining"
                                                        value={creditsRemaining}
                                                        onChange={(e) => setCreditsRemaining(parseInt(e.target.value))}
                                                        className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label htmlFor="templates" className="block text-sm font-medium leading-6 text-darkGrey">
                                                    Allowed Templates
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex flex-wrap gap-2 mb-2">
                                                        {templates.map((template, index) => (
                                                            <span key={index} className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                                                                {template}
                                                                <button
                                                                    onClick={() => handleRemoveTemplate(template)}
                                                                    className="ml-2 text-blue-800 hover:text-blue-900"
                                                                >
                                                                    ×
                                                                </button>
                                                            </span>
                                                        ))}
                                                    </div>
                                                    <form onSubmit={handleAddTemplate} className="flex">
                                                        <input
                                                            type="text"
                                                            value={newTemplate}
                                                            onChange={(e) => setNewTemplate(e.target.value)}
                                                            placeholder="Type a template name"
                                                            className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primaryBlue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        >
                                                            Add
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                            {isLoading && <Spinner />}
                                            {errorMessage && (
                                                <AlertPopup
                                                    message={errorMessage}
                                                    type="error"
                                                    onClose={() => setErrorMessage('')}
                                                    duration={2000}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:mt-10 flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-primaryBlue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleSave}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={() => setOpen(false)}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
