import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const createPortalSession = async (returnUrl) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/payment/create-portal-session`,
            { return_url: returnUrl },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        return response.data.portal_url;
    } catch (error) {
        throw new Error('Error creating portal session');
    }
};

const createCheckoutSession = async (customerEmail, planId, successUrl, cancelUrl) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/payment/create-checkout-session`,
            {
                customer_email: customerEmail,
                plan_id: planId,
                success_url: successUrl,
                cancel_url: cancelUrl,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        return response.data.checkout_session_url;
    } catch (error) {
        throw new Error('Error creating checkout session');
    }
};

const validateSession = async (sessionId) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/payment/validate-session`,
            {
                session_id: sessionId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            throw new Error('Invalid Stripe session ID');
        } else {
            throw new Error('Error validating session');
        }
    }
};

export { createPortalSession, createCheckoutSession, validateSession };

