import React, { useState, useEffect, useCallback } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getUserProfile, logoutUser, setAuthToken } from '../../services/userService';
import { createPortalSession, validateSession } from '../../services/subscriptionService';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Logo = () => (
  <div className="flex-shrink-0">
    <Link to="/">
      <img className="h-8 w-8" src="/PivotCV-Icon-Light.svg" alt="PivotCV Logo" />
    </Link>
  </div>
);

const NavLink = ({ item }) => (
  <Link
    to={item.href}
    className={classNames(
      item.current
        ? 'bg-primaryBlue text-white'
        : 'text-white hover:bg-primaryBlue hover:text-white',
      item.name === 'Convert CVs'
        ? 'bg-green-600 text-white hover:bg-green-700'
        : '',
      'rounded-md px-3 py-2 text-sm font-medium'
    )}
    aria-current={item.current ? 'page' : undefined}
  >
    {item.name}
  </Link>
);

const DesktopNavigation = ({ navigation }) => (
  <div className="hidden md:block">
    <div className="ml-10 flex items-baseline space-x-4">
      {navigation.map((item) => (
        <NavLink key={item.name} item={item} />
      ))}
    </div>
  </div>
);

const UserAvatar = ({ user }) => (
  user?.icon_data ? (
    <img
      className="h-8 w-8 rounded-full"
      src={`data:${user.icon_mimetype};base64,${user.icon_data}`}
      alt=""
    />
  ) : (
    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
);

const DesktopUserMenu = ({ user, userNavigation, guestNavigation }) => (
  <div className="hidden md:block">
    <div className="ml-4 flex items-center md:ml-6">
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
            <span className="sr-only">Open user menu</span>
            <UserAvatar user={user} />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {(user ? userNavigation : guestNavigation).map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    onClick={item.onClick}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    {item.name}
                    {item.name === 'Manage Subscription' && user?.subscription_status && (
                      <div className="mt-1 text-xs text-gray-500">
                        Status: {user.subscription_status.charAt(0).toUpperCase() + user.subscription_status.slice(1)}
                      </div>
                    )}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  </div>
);

const MobileMenuButton = ({ open }) => (
  <div className="-mr-2 flex md:hidden">
    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-primaryBlue hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  </div>
);

const MobileMenu = ({ navigation, user, userNavigation, guestNavigation }) => (
  <Disclosure.Panel className="md:hidden">
    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
      {navigation.map((item) => (
        <Disclosure.Button
          key={item.name}
          as={Link}
          to={item.href}
          className={classNames(
            item.current ? 'bg-superDarkBlue text-white' : 'text-gray-300 hover:bg-primaryBlue hover:text-white',
            item.name === 'Application' ? 'bg-green-600 text-white hover:bg-green-700' : '',
            'block rounded-md px-3 py-2 text-base font-medium'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          {item.name}
        </Disclosure.Button>
      ))}
    </div>
    <div className="border-t border-gray-700 pb-3 pt-4">
      <div className="flex items-center px-5">
        {user && (
          <>
            <div className="flex-shrink-0">
              <UserAvatar user={user} />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-white">{user.name}</div>
              <div className="mt-2 text-sm font-medium leading-none text-gray-400">{user.email}</div>
            </div>
          </>
        )}
      </div>
      <div className="mt-3 space-y-1 px-2">
        {(user ? userNavigation : guestNavigation).map((item) => (
          <Disclosure.Button
            key={item.name}
            as={Link}
            to={item.href}
            onClick={item.onClick}
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-primaryBlue hover:text-white"
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </div>
  </Disclosure.Panel>
);

const Footer = () => (
  <footer className="bg-white mt-auto relative z-10">
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="border-t border-gray-200 py-4 text-center text-sm text-gray-500">
        <span className="block sm:inline">&copy; 2024 PivotCV, Inc. All rights reserved.</span>
      </div>
      <div className="py-4 text-center text-sm text-gray-500">
        <a href="mailto:Support@PivotCV.com.au" className="text-gray-500 hover:text-slateGrey hover:underline">
          Support@PivotCV.com.au
        </a>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center mt-4 px-6 py-2 space-y-4 sm:space-y-0">
        <a
          href="/PivotCV-Website-Terms-and-Conditions.pdf"
          className="text-xs text-gray-500 hover:text-slateGrey hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
        <a
          href="/PivotCV-Privacy-Policy.pdf"
          className="text-xs text-gray-500 hover:text-slateGrey hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="/PivotCV-Data-Breach-Policy.pdf"
          className="text-xs text-gray-500 hover:text-slateGrey hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Breach Policy
        </a>
      </div>
    </div>
  </footer>
);

const AppShell = ({ children, currentPage, loginRequired = false }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleError = useCallback((error, defaultMessage) => {
    let message = error.response?.data?.error || error.message || defaultMessage;
    if (error.response?.status === 401) {
      message = error.response.data.error === 'Token expired' 
        ? 'Session Expired. Please log in again.' 
        : 'Unauthorized. Please log in again.';
      localStorage.clear();
      setUser(null);
      navigate('/login');
    }
    setAlertMessage(message);
    setAlertType('error');
    setShowAlert(true);
  }, [navigate]);

  const fetchUserData = useCallback(async () => {
    try {
      const userData = await getUserProfile();
      setUser(userData);
    } catch (error) {
      handleError(error, 'Error fetching user data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      setAuthToken(accessToken);
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [fetchUserData]);

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');
    if (sessionId) {
      validateSession(sessionId)
        .then(sessionData => {
          if (sessionData.valid) {
            let message = sessionData.payment_status === 'paid' 
              ? 'Payment successful! Your subscription is now active.' 
              : sessionData.payment_status === 'unpaid'
                ? 'Payment pending. Please complete the payment to activate your subscription.'
                : 'No payment required. Your subscription is now active.';
            if (sessionData.subscription_id) {
              message += ` Subscription ID: ${sessionData.subscription_id}.`;
            }
            setAlertMessage(message);
            setAlertType(sessionData.payment_status === 'unpaid' ? 'warning' : 'success');
            setShowAlert(true);
          } else {
            setAlertMessage('Session validation failed. Please try again.');
            setAlertType('error');
            setError('Session validation failed. Please try again.');
            setShowAlert(true);
          }
        })
        .catch(error => handleError(error, 'Error validating session. Please try again.'));
      navigate(location.pathname, { replace: true });
    }
  }, [location.search, location.pathname, handleError, navigate]);

  const handleLogout = useCallback(async () => {
    setIsLoggingOut(true);
    setAlertMessage('Logging out...');
    setAlertType('info');
    setShowAlert(true);
    try {
      await logoutUser();
      setAlertMessage('Logged out successfully. Redirecting...');
      setAlertType('success');
      setTimeout(() => navigate('/'), 1500);
    } catch (error) {
      handleError(error, 'Error logging out. Please try again.');
      setIsLoggingOut(false);
    }
  }, [handleError, navigate]);

  const handleManageSubscription = useCallback(async () => {
    try {
      const portalUrl = await createPortalSession(window.location.href);
      window.location.href = portalUrl;
    } catch (error) {
      handleError(error, 'Error managing subscription. Please try again.');
    }
  }, [handleError]);

  const navigation = [
    { name: 'Home', href: '/Home', current: currentPage === 'Home' },
    { name: 'About', href: '/About', current: currentPage === 'About' },
    { name: 'Pricing', href: '/Pricing', current: currentPage === 'Pricing' },
    { name: 'Roadmap', href: '/Roadmap', current: currentPage === 'Roadmap' },
    { name: 'How It Works', href: '/How-It-Works', current: currentPage === 'How-It-Works' },
    { name: 'Scenarios', href: '/Scenarios', current: currentPage === 'Scenarios' },
    ...(user?.subscription_status === 'active' ? [{ name: 'Convert CVs', href: '/Application', current: currentPage === 'Application' }] : []),
  ];

  const userNavigation = [
    { name: 'Your Profile', href: '/profile' },
    { name: 'Manage Subscription', href: '#', onClick: handleManageSubscription },
    ...(user?.user_role === 'admin' ? [
      { name: 'Admin', href: '/admin' },
      { name: 'Demo', href: '/Application/Demo' },
      { name: 'Chat', href: '/Application/Chat' },
    ] : []),
    { name: 'Sign out', href: '#', onClick: handleLogout },
  ];

  const guestNavigation = [
    { name: 'Log in', href: '/login' },
    { name: 'Sign up', href: '/register' },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Disclosure as="nav" className="bg-superDarkBlue relative z-20">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <Logo />
                  <DesktopNavigation navigation={navigation} />
                </div>
                <DesktopUserMenu user={user} userNavigation={userNavigation} guestNavigation={guestNavigation} />
                <MobileMenuButton open={open} />
              </div>
            </div>
            <MobileMenu 
              navigation={navigation} 
              user={user} 
              userNavigation={userNavigation} 
              guestNavigation={guestNavigation} 
            />
          </>
        )}
      </Disclosure>

      <main className="flex-grow bg-white relative z-10">
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {loginRequired && loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          ) : loginRequired && error ? (
            <div className="flex items-center justify-center h-full">
              <div className="mt-48 text-center">
                <h2 className="text-2xl font-semibold mb-4">Oh no. An error has occurred.</h2>
                <div className="flex justify-center space-x-4">
                  <Link to="/login" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
                    Login
                  </Link>
                  <Link to="/" className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50">
                    Go Home
                  </Link>
                  <Link to="/pricing" className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50">
                    See Pricing
                  </Link>
                </div>
              </div>
            </div>
          ) : loginRequired && !user ? (
            <div className="flex flex-col min-h-screen">
              <div className="flex-grow flex items-center justify-center">
                <div className="text-center">
                  <h2 className="text-2xl font-semibold mb-4">Please log in to access this page.</h2>
                  <div className="flex justify-center space-x-4">
                    <Link
                      to="/login"
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition duration-300"
                    >
                      Login
                    </Link>
                    <Link
                      to="/"
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition duration-300"
                    >
                      Go Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            children
          )}
        </div>
      </main>

      <Footer />

      {showAlert && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-md ${alertType === 'error' ? 'bg-red-500' : 'bg-green-500'} text-white z-50`}>
          <p>{alertMessage}</p>
          <button onClick={() => setShowAlert(false)} className="mt-2 px-2 py-1 bg-white text-gray-800 rounded">Close</button>
        </div>
      )}
      {isLoggingOut && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-lg font-semibold text-gray-700">Logging out...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppShell;