// RadioButtonGroup.js
import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Tooltip from '../layout/Tooltip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const RadioButtonGroup = ({ title, options, TooltipMessage, onSelect, selectedOption }) => {
  const handleChange = (option) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(option);
    }
  };

  const optionsArray = Array.isArray(options) ? options : [];

  const selected = optionsArray.find(option => 
    option.title === selectedOption || option.id === selectedOption
  ) || optionsArray[0];

  if (optionsArray.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium leading-6 text-darkGrey">{title}</h3>
        <Tooltip message={TooltipMessage} position='left' skew='right' iconComponents='info' />
      </div>
      <RadioGroup
        value={selected}
        onChange={handleChange}
        className="mt-2"
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          {optionsArray.map((option) => (
            <RadioGroup.Option
              key={option.id}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  'relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none',
                  active ? 'ring-2 ring-indigo-500 ring-opacity-60 ring-offset-2' : '',
                  checked
                    ? 'bg-primaryBlue text-white'
                    : 'bg-white hover:bg-gray-50'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={classNames(
                            checked ? 'text-white mr-1' : 'text-darkGrey',
                            'font-medium'
                          )}
                        >
                          {option.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? 'text-indigo-100' : 'text-gray-500',
                            'inline'
                          )}
                        >
                          <span>{option.description}</span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <div className="shrink-0 text-white">
                        <CheckCircleIcon className="h-6 w-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioButtonGroup;