import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Upload, FileText, X, Loader2 } from 'lucide-react';
import { Label } from './Label';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from './Tooltip';

const FileUpload = ({ isResume, onFileUpload, id, selectedFile, template = 'advanced', label, tooltipContent }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const allowedTypes = useMemo(() => [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/json',
  ], []);

  const fileTypeNames = useMemo(() => ({
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'application/msword': 'DOC',
    'application/json': 'JSON',
  }), []);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(e.type === 'dragenter' || e.type === 'dragover');
  }, []);

  const uploadFile = useCallback(async (file) => {
    setIsUploading(true);
    try {
      await onFileUpload(file);
      setIsUploading(false);
    } catch (error) {
      setError('Error uploading file. Please try again.');
      setShowError(true);
      setIsUploading(false);
    }
  }, [onFileUpload]);

  const handleFiles = useCallback((file) => {
    setError(null);
    setShowError(false);
    if (!allowedTypes.includes(file.type)) {
      setError(`Please upload a ${Object.values(fileTypeNames).join(', ')} file`);
      setShowError(true);
      return;
    }
    uploadFile(file);
  }, [allowedTypes, fileTypeNames, uploadFile]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  }, [handleFiles]);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  }, [handleFiles]);

  const removeFile = useCallback(() => {
    onFileUpload(null);
    setError(null);
    setShowError(false);
  }, [onFileUpload]);

  const renderAdvancedTemplate = () => (
    <div className="space-y-6">
      <div>
        <h3 className="font-semibold mb-4 text-xl text-gray-800">
          Or upload your own {isResume ? "resume" : "job description"}:
        </h3>
        <div
          className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-all duration-300 h-60 flex flex-col justify-center items-center relative ${
            isDragActive
              ? 'border-blue-500 bg-blue-50'
              : showError
              ? 'border-red-500 bg-red-50'
              : selectedFile
              ? 'border-green-500 bg-green-50'
              : 'border-gray-300 hover:border-blue-400 hover:bg-blue-50'
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id={`fileInput-${id}`}
            className="hidden"
            accept={allowedTypes.join(',')}
            onChange={handleChange}
            aria-label={`Upload your ${isResume ? "resume" : "job description"}`}
          />
          <label htmlFor={`fileInput-${id}`} className="cursor-pointer w-full h-full flex flex-col items-center justify-center">
            {isUploading ? (
              <Loader2 className="h-12 w-12 text-blue-500 mb-4 animate-spin" />
            ) : selectedFile ? (
              <>
                <FileText className="h-12 w-12 text-green-500 mb-4" />
                <p className="text-gray-600">{selectedFile.name}</p>
                <p className="text-sm text-green-600 mt-2">File uploaded successfully</p>
                <button
                  onClick={(e) => { e.preventDefault(); removeFile(); }}
                  className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-300"
                  aria-label="Remove file"
                >
                  <X className="h-6 w-6" />
                </button>
              </>
            ) : (
              <>
                <Upload className={`h-12 w-12 mb-4 ${showError ? 'text-red-500' : 'text-blue-500'}`} />
                <p className={`text-sm ${showError ? 'text-red-600' : 'text-gray-600'}`}>
                  {showError ? error : `Click to upload or drag and drop your ${Object.values(fileTypeNames).join(', ')} file here`}
                </p>
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );

  const renderSimpleTemplate = () => (
    <div className="space-y-2">
      <TooltipProvider>
        <Label htmlFor={`fileInput-${id}`}>{label}</Label>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className="h-32 md:h-40 rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-600 hover:border-blue-500 transition-colors cursor-pointer"
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  document.getElementById(`fileInput-${id}`).click();
                }
              }}
              onClick={() => document.getElementById(`fileInput-${id}`).click()}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id={`fileInput-${id}`}
                className="hidden"
                accept={allowedTypes.join(',')}
                onChange={handleChange}
                aria-label={`Upload your ${label}`}
              />
              <span className="sr-only">Upload your {label}</span>
              {selectedFile ? selectedFile.name : 'Click or drag file to upload'}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{tooltipContent}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );

  return template === 'advanced' ? renderAdvancedTemplate() : renderSimpleTemplate();
};

export default FileUpload;