import React from 'react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const NumberInput = ({ 
  title, 
  description, 
  value, 
  onChange,
  min = 0, 
  max = Infinity, 
  step = 1
}) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange(Math.min(value + step, max));
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      onChange(Math.max(value - step, min));
    }
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <div className="py-2 px-3 bg-white border border-gray-200 rounded-lg">
      <div className="w-full flex justify-between items-center gap-x-3">
        <div>
          <span className="block font-medium text-sm text-gray-800">
            {title}
          </span>
          <span className="block text-xs text-gray-500">
            {description}
          </span>
        </div>
        <div className="flex items-center gap-x-1.5">
          <button
            type="button"
            className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleDecrement}
            disabled={value <= min}
          >
            <MinusSmallIcon className="h-4 w-4" />
          </button>
          <input
            className="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0"
            type="text"
            value={value}
            onChange={handleInputChange}
          />
          <button
            type="button"
            className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleIncrement}
            disabled={value >= max}
          >
            <PlusSmallIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberInput;