import React from 'react';
import AppShell from '../../components/layout/AppShell';
import { Helmet } from 'react-helmet';

const roadmapItems = [
    {
        version: 'Phase 1',
        status: 'Complete',
        summary: 'Initial proof of concept demonstrating core capabilities.',
        points: [
            'PDF rendering only.',
            'Standardising CV format.',
            'Rewriting CV to third person.',
            'Basic layout and design implementation.'
        ]
    },
    {
        version: 'Phase 2',
        status: 'Complete',
        summary: 'Introduction of tailoring features and system optimisations.',
        points: [
            'Add a position description to tailor a CV.',
            'Overall system optimisations.',
            'Enhanced user interface for better usability.',
            'Bug fixes and performance improvements.'
        ]
    },
    {
        version: 'Phase 3',
        status: 'Complete',
        summary: 'Release of PivotCV Version 1, transitioning to a full-stack application.',
        points: [
            'Moved to a full-stack application instead of a POC framework.',
            'Added user account management.',
            'Added subscription management.',
            'Added support and documentation.',
            'CV tailoring improvements including keyword analysis and sentiment analysis to optimally align a CV to a job description.'
        ]
    },
    {
        version: 'Phase 4',
        status: 'Complete',
        summary: 'Version 1.1 of PivotCV with enhanced conversion speed.',
        points: [
            'Optimisations to the conversion process, reducing CV conversion time from 30-60 seconds to an average of 5 seconds.',
            'Improved error handling during CV conversion.',
            'Enhanced logging for better debugging and monitoring.',
            'Minor UI tweaks and adjustments.'
        ]
    },
    {
        version: 'Phase 5',
        status: 'Complete',
        summary: 'Version 2.0 of PivotCV with a comprehensive rebuild and new features.',
        points: [
            'Complete rebuild of the CV conversion engine.',
            'Convert CV to DocX format.',
            'Complete ATS formatting adherence.',
            'Bulk CV conversions.',
            'Length customisation.',
            'Section limit customisation.',
            'Main application page redesign for an easier-to-use interface.'
        ]
    }
];

const UpcomingItems = [
    {
        version: 'Phase 6',
        status: 'Upcoming',
        summary: 'CV Template creation tool to allow for in-app creation and use of user-defined CV templates.',
        points: [
            'Build a drag-and-drop CV template creation tool.',
            'Enable users to save and reuse custom CV templates.',
            'Provide template sharing options within the community.',
            'Integrate template previews before applying.'
        ]
    },
    {
        version: 'Phase 7',
        status: 'Upcoming',
        summary: 'Enhanced customisation options for the converted CV.',
        points: [
            'Allow users to include or exclude specific details in the converted CV.',
            'Offer multiple layout options for different CV sections.',
            'Customise fonts, colours, and styles to match personal branding.',
            'Introduce a real-time preview of customisations.'
        ]
    },
    {
        version: 'Phase 8',
        status: 'Upcoming',
        summary: 'Custom CV creation from scratch, tailored to user preferences.',
        points: [
            'Build a dynamic triage system to gather user information.',
            'Utilise AI to understand job requirements and military accreditations.',
            'Label and highlight relevant experiences that users may have forgotten.',
            'Generate tailored and generic CVs based on user input.',
            'Incorporate industry-specific keywords and phrases for better job matching.'
        ]
    },
    {
        version: 'Phase 9',
        status: 'Upcoming',
        summary: 'Advanced analytics and insights for CV improvement.',
        points: [
            'Provide feedback on CV strength and areas for improvement.',
            'Analyse CVs against job postings for optimal alignment.',
            'Offer suggestions for skill enhancements and additional certifications.',
            'Track application outcomes to refine CV recommendations.'
        ]
    }
];


const getStatusClasses = (status) => {
    switch (status) {
        case 'Complete':
            return 'bg-green-500';
        case 'In Progress':
            return 'bg-yellow-500';
        case 'Upcoming':
            return 'bg-gray-500';
        default:
            return 'bg-blue-500';
    }
};

const Roadmap = ({ user }) => {
    return (
        <AppShell currentPage="Roadmap" loginRequired={false}>
            <Helmet>
                <title>PivotCV | Roadmap | CV Converter - AI-Powered Resume Tool</title>
                <meta
                    name="description"
                    content="Explore the pricing options for our AI-powered CV Converter. Create a professional, tailored resume for free and unlock additional features with our affordable subscription plans. Boost your job search without breaking the bank."
                />
                <meta
                    name="keywords"
                    content="pricing, CV converter pricing, resume converter pricing, free CV converter, free resume converter, AI-powered CV converter, AI-driven resume conversion, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume, affordable CV tool, affordable resume tool, subscription plans"
                />
            </Helmet>
            <main>
                <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
                    <div
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7B61FF] to-[#4A90E2] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>

                    <div id="top" className="min-h-[calc(100vh-25vh)] py-24 sm:py-24">

                        <div className="min-h-[calc(100vh-25vh)] mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl lg:text-center">
                                <h2 className="mt-2 text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">Our Journey and Progress</h2>
                                <p className="mt-6 text-lg leading-8 text-slateGrey">
                                    Discover the milestones we've achieved and the features we've introduced to enhance your experience. Our roadmap showcases the continuous improvements and updates to PivotCV.
                                </p>
                            </div>


                            <div className="flex flex-row">
                                <div className="w-1/2 p-4">
                                    <div className="max-w-xl mx-auto p-8 mt-12">
                                        <div className="flow-root">
                                            <ul className="-mb-8">
                                                {roadmapItems.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="relative pb-8">
                                                            {index !== roadmapItems.length - 1 && (
                                                                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                                            )}
                                                            <div className="relative flex items-start space-x-3">
                                                                <div>
                                                                    <div className="relative px-1">
                                                                        <div className={`h-8 w-8 ${getStatusClasses(item.status)} rounded-full ring-8 ring-white flex items-center justify-center`}>
                                                                            <svg className="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                                stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="min-w-0 flex-1 py-0">
                                                                    <div className="text-md text-gray-500">
                                                                        <div>
                                                                            <span className="font-medium text-darkGrey mr-2">{item.version}</span>
                                                                            <p
                                                                                className={`my-0.5 relative inline-flex items-center bg-white rounded-full border border-lightGrey px-3 py-0.5 text-sm`}>
                                                                                <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                                                    <span className={`h-1.5 w-1.5 rounded-full ${getStatusClasses(item.status)}`} aria-hidden="true"></span>
                                                                                </div>
                                                                                <div className="ml-3.5 font-medium text-darkGrey">{item.status}</div>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2 text-gray-700">
                                                                        <p>{item.summary}</p>
                                                                        <ul className="list-disc list-inside">
                                                                            {item.points.map((point, idx) => (
                                                                                <li key={idx}>{point}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>



                                <div className="w-1/2 p-4">
                                    <div className="max-w-xl mx-auto p-8 mt-12">
                                        <div className="flow-root">
                                            <ul className="-mb-8">
                                                {UpcomingItems.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="relative pb-8">
                                                            {index !== UpcomingItems.length - 1 && (
                                                                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                                            )}
                                                            <div className="relative flex items-start space-x-3">
                                                                <div>
                                                                    <div className="relative px-1">
                                                                        <div className={`h-8 w-8 ${getStatusClasses(item.status)} rounded-full ring-8 ring-white flex items-center justify-center`}>
                                                                            <svg className="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                                stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="min-w-0 flex-1 py-0">
                                                                    <div className="text-md text-gray-500">
                                                                        <div>
                                                                            <span className="font-medium text-darkGrey mr-2">{item.version}</span>
                                                                            <p
                                                                                className={`my-0.5 relative inline-flex items-center bg-white rounded-full border border-lightGrey px-3 py-0.5 text-sm`}>
                                                                                <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                                                    <span className={`h-1.5 w-1.5 rounded-full ${getStatusClasses(item.status)}`} aria-hidden="true"></span>
                                                                                </div>
                                                                                <div className="ml-3.5 font-medium text-darkGrey">{item.status}</div>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2 text-gray-700">
                                                                        <p>{item.summary}</p>
                                                                        <ul className="list-disc list-inside">
                                                                            {item.points.map((point, idx) => (
                                                                                <li key={idx}>{point}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Back to top button */}
                            <div className="flex items-center justify-center">
                                <div className="flex items-center gap-x-6">
                                    <a href="#top" className="text-sm font-semibold leading-6 text-darkGrey">
                                        Back to top <span aria-hidden="true">→</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AppShell>
    );
};

export default Roadmap;
