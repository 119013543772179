// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Static/Home';
import About from './pages/Static/About';
import Pricing from './pages/Static/Pricing';
import Profile from './pages/User/Profile';
import Login from './pages/User/Login';
import Register from './pages/User/Register';
import ResetPassword from './pages/User/ResetPassword';
import Admin from './pages/Admin/Admin';
import NotFoundPage from './pages/Static/NotFoundPage';
import ErrorBoundaryPage from './pages/Static/ErrorBoundaryPage';
import Application from './pages/Application/Application';
import { UserProvider } from './UserContext';
import TermsConditions from './pages/Static/TermsConditions';
import HowitWorks from './pages/Static/HowItWorks';
import Roadmap from './pages/Static/Roadmap';
import DataPrivacy from './pages/Static/Data';
import ApplicationDemo from './pages/Application/ApplicationDemo';
import ApplicationChat from './pages/Application/ApplicationChat';
import CVConverter from './pages/Application/Application';
import Scenarios from './pages/Static/Scenarios';

const App = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/error" element={<ErrorBoundaryPage />} />
          <Route path="/Application" element={<Application />} />
          <Route path="/Roadmap" element={<Roadmap />} />
          <Route path="/DataPrivacy" element={<DataPrivacy />} />
          <Route path="/How-It-Works" element={<HowitWorks />} />
          <Route path="/Terms-and-Conditions" element={<TermsConditions />} />
          <Route path="/Application/Demo" element={<ApplicationDemo />} />
          <Route path="/Application/Chat" element={<ApplicationChat />} />
          <Route path="/Application" element={<CVConverter />} />
          <Route path="/Scenarios" element={<Scenarios />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default App;
