import React, { useEffect, useState } from 'react';
import AppShell from '../../components/layout/AppShell';
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import {
  CloudArrowUpIcon,
  UserIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  MagnifyingGlassIcon,
  Square3Stack3DIcon,
  DocumentIcon,
  ChatBubbleLeftRightIcon,
  EyeIcon,
  BriefcaseIcon,
  CheckCircleIcon,
  SparklesIcon,
  HandThumbUpIcon,
  ArrowUpRightIcon
} from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { ArrowUpCircle } from 'lucide-react';

const faqs = [
  {
    id: 1,
    question: "How do I use PivotCV?",
    answer: "To use PivotCV, simply upload your existing CV and the job description. Our tool will do the rest, tailoring your CV to match the job requirements.",
  },
  {
    id: 2,
    question: "How do I cancel my subscription?",
    answer: "You can cancel your subscription by navigating to 'My Profile', selecting 'Manage Subscription', and following the cancellation instructions.",
  },
  {
    id: 3,
    question: "How much does PivotCV cost?",
    answer: "You can view our pricing plans on the Pricing page. Choose the plan that best suits your needs and get started today.",
  },
  {
    id: 4,
    question: "How do I update my details?",
    answer: "You can update your personal details such as Name, Organisation, and Email in the 'My Profile' section. Just navigate there to make any changes.",
  }
]

const featuresSummary = [
  {
    name: 'Resume Personalisation',
    description: 'Tailor your CV to specific job descriptions or RFQ documents. Upload the relevant documents, and our app will highlight the key skills and experiences that align with the employer\'s requirements.',
    icon: PencilSquareIcon,
    href: '#FeatureDetail_1',
  },
  {
    name: 'CV Creation from Text',
    description: 'Easily create a professional CV by uploading your existing resume or entering plain text. Our advanced algorithms will extract the relevant information and convert it into a standardised format.',
    icon: CloudArrowUpIcon,
    href: '#FeatureDetail_2',
  },
  {
    name: 'Maximise Efficiency',
    description: 'Revolutionise the way you create your CV with our ultra-fast tool. Convert, personalise, and correct your CV in less than 30 seconds, allowing you to apply for your dream job quicker than ever.',
    icon: UserIcon,
    href: '#FeatureDetail_3',
  },
  {
    name: 'Format Standardisation',
    description: 'Stand out from the crowd with a clean, elegant, and consistent CV format. Our app removes any inconsistencies and ensures your resume is visually appealing and easy to read.',
    icon: DocumentTextIcon,
    href: '#FeatureDetail_4',
  }
]

const featuresDetail_1 = [
  {
    name: 'Intelligent Keyword Analysis',
    description: 'Our advanced AI scans the job description or RFQ to identify crucial keywords and phrases. It then strategically incorporates these terms into your CV, ensuring optimal placement and frequency. This process not only helps your application pass through Applicant Tracking Systems (ATS) but also resonates with human recruiters, significantly increasing your chances of securing an interview.',
    icon: MagnifyingGlassIcon,
  },
  {
    name: 'Dynamic Content Customisation',
    description: 'Our tool intelligently tailors your entire CV content to the job description. It adapts your professional summary, reframes achievements to showcase their relevance, and prioritises your educational background based on the job\'s demands. PivotCV also applies industry-specific optimisation, adjusting terminology and formatting to match sector expectations, ensuring every element of your CV supports your application and speaks the language of your target industry.',
    icon: DocumentIcon,
  },
  {
    name: 'Comprehensive Requirement Mapping',
    description: 'PivotCV conducts a thorough analysis of the job\'s requirements, including hard skills, soft skills, and industry-specific competencies. It then meticulously aligns your experiences and qualifications with these requirements, reorganising your CV sections to emphasise the most relevant achievements. This creates a compelling narrative of your suitability for the role, tailored to each specific position.',
    icon: Square3Stack3DIcon,
  },
];

const featuresDetail_2 = [
  {
    name: 'Intelligent Information Extraction',
    description: 'Our sophisticated algorithms accurately identify and extract crucial information from your uploaded resume or plain text input, ensuring no important details are missed.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Standardised Sections',
    description: 'The app organises your CV into clear, standardised sections such as Personal Details, Work Experience, Education, Skills, and more, making it easy for employers to find the information they need.',
    icon: EyeIcon,
  },
  {
    name: 'Multiple File Formats',
    description: 'Whether your existing resume is in PDF, Word, or plain text format, our app can handle it all. Simply upload your file, and let us take care of the rest.',
    icon: BriefcaseIcon,
  },
]

const featuresDetail_3 = [
  {
    name: 'Rapid Conversion',
    description: 'Our tool quickly converts your raw text or existing resume into a polished CV. This fast processing means you spend less time waiting and more time applying for jobs.',
    icon: ArrowUpRightIcon,
  },
  {
    name: 'Quick Personalisation',
    description: 'Instantly tailor your CV to specific job descriptions. Our app analyses and integrates key elements in seconds, ensuring your resume stands out immediately.',
    icon: EyeIcon,
  },
  {
    name: 'Error Correction',
    description: 'Avoid the delays of manual proofreading. Our tool detects and corrects common mistakes in real time, ensuring your CV is error-free and ready to impress at a moment’s notice.',
    icon: CheckCircleIcon,
  },
]

const featuresDetail_4 = [
  {
    name: 'Consistent Formatting',
    description: 'Our app ensures that your CV follows a consistent format throughout, with uniform fonts, font sizes, and spacing, creating a polished and professional look.',
    icon: SparklesIcon,
  },
  {
    name: 'Improved Readability',
    description: 'By standardising the format of your CV, we make it easier for employers to scan and find the information they need quickly, increasing your chances of being noticed.',
    icon: HandThumbUpIcon,
  },
  {
    name: 'ATS Compliance',
    description: 'Our standardised format is designed to be compatible with Applicant Tracking Systems (ATS), ensuring your CV is not overlooked due to formatting issues.',
    icon: ArrowUpRightIcon,
  },
]

const About = () => {
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const video = document.getElementById("demoVideo");
    const playButton = document.getElementById("playButton");
    const watchAgainButton = document.getElementById("watchAgainButton");
    const tryNowButton = document.getElementById("tryNowButton");
    const overlay = document.getElementById("overlay");

    const showOverlay = () => {
      overlay.classList.remove('hidden'); // Show overlay
      playButton.style.display = 'flex'; // Show play button initially
      watchAgainButton.style.display = 'none'; // Hide watch again button
      tryNowButton.style.display = 'none'; // Hide try now button
    };

    const showEndOverlay = () => {
      overlay.classList.remove('hidden'); // Show overlay
      playButton.style.display = 'none'; // Hide play button
      watchAgainButton.style.display = 'flex'; // Show watch again button
      tryNowButton.style.display = 'flex'; // Show try now button
    };

    const hideOverlay = () => {
      overlay.classList.add('hidden'); // Hide overlay
      playButton.style.display = 'none'; // Hide play button
    };

    showOverlay(); // Initially show overlay

    video.addEventListener('play', hideOverlay);
    video.addEventListener('pause', showOverlay);
    video.addEventListener('ended', () => {
      setVideoEnded(true);
      showEndOverlay();
    });

    playButton.addEventListener('click', () => {
      setVideoEnded(false);
      video.play();
      hideOverlay();
    });

    watchAgainButton.addEventListener('click', () => {
      setVideoEnded(false);
      video.currentTime = 0; // Restart video
      video.play();
      hideOverlay();
    });

    tryNowButton.addEventListener('click', () => {
      window.location.href = '/Register'; // Redirect to Register page
    });

    return () => {
      video.removeEventListener('play', hideOverlay);
      video.removeEventListener('pause', showOverlay);
      video.removeEventListener('ended', null);
      playButton.removeEventListener('click', null);
      watchAgainButton.removeEventListener('click', null);
      tryNowButton.removeEventListener('click', null);
    };
  }, []);

  return (
    <AppShell currentPage="About" loginRequired={false}>
      <Helmet>
        <title>PivotCV | About | CV Converter - AI-Powered Resume Tool</title>
        <meta
          name="description"
          content="Learn more about our free AI-powered CV Converter. Our innovative tool helps job seekers create professional, tailored resumes in seconds. Discover how we can revolutionise your job search and increase your chances of landing your dream job."
        />
        <meta
          name="keywords"
          content="about CV converter, about resume converter, free CV converter, free resume converter, AI-powered CV converter, AI-driven resume conversion, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume, job application, career boost, increase job prospects, land dream job"
        />
      </Helmet>

      {/* Header */}

      {/* Page-specific content */}


      {/* Feature Summary */}
      <main>
        <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#FF6F61] to-[#FF9A8B] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>


          <div id="top" className="min-h-[calc(100vh-25vh)] py-24 sm:py-24">

            <div className="min-h-[calc(100vh-25vh)] mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="text-base font-semibold leading-7 text-primaryBlue">Revolutionise Your CV</p>
                <h2 className="mt-2 text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">Effortless CV Conversion and Optimisation</h2>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Transform your CV into a professional, tailored document that showcases your skills and experience. Our powerful tools simplify the process of creating a standout CV, saving you time and effort.
                </p>
              </div>

              <div className="mt-16 sm:mt-16">
                <div className="relative rounded-xl bg-superDarkBlue/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:rounded-2xl lg:p-4 overflow-hidden shadow-2xl">
                  <video
                    id="demoVideo"
                    src="/PivotCV Demo Video.mp4"
                    className={`w-full h-auto rounded-md transition-opacity duration-1000 ${videoEnded ? 'opacity-50' : 'opacity-100'}`}
                    muted
                    poster='/PivotCVDemoVideoPoster.png'
                  >
                    Your browser does not support the video tag.
                  </video>
                  <div id="overlay" className={`absolute inset-0 flex items-center justify-center bg-superDarkBlue bg-opacity-50 transition-opacity duration-1000 ${videoEnded ? 'opacity-100' : 'hidden'}`}>
                    <button id="playButton" className="bg-white text-darkGrey px-6 py-3 rounded-md shadow-lg text-lg font-semibold hover:bg-gray-200 transition-colors max-w-xs w-full sm:w-auto">
                      Discover the 3-Step CV Conversion Process
                    </button>
                    <button id="watchAgainButton" className="hidden bg-white text-darkGrey px-6 py-3 rounded-md shadow-lg text-lg font-semibold hover:bg-gray-200 transition-colors mx-2 max-w-xs w-full sm:w-auto">
                      Watch Again
                    </button>
                    <button id="tryNowButton" className="hidden bg-primaryBlue text-white px-6 py-3 rounded-md shadow-lg text-lg font-semibold hover:bg-lightBlue transition-colors mx-2 max-w-xs w-full sm:w-auto">
                      Try Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-16 mx-auto max-w-7xl px-6 sm:py-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">Discover the Power of PivotCV</p>
                  <p className="mt-6 text-lg leading-8 text-slateGrey">
                    Explore our features and see how AI-driven customisation can elevate your job application process.
                  </p>
                </div>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-16 lg:mt-16 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                  {featuresSummary.map((feature) => (
                    <div key={feature.name} className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-darkGrey">
                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primaryBlue">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        {feature.name}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-slateGrey">{feature.description}</dd>
                      <p className="mt-6">
                        <a href={feature.href} className="text-sm font-semibold leading-6 text-primaryBlue">
                          Learn more <span aria-hidden="true">→</span>
                        </a>
                      </p>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          {/* Feature Detail 1 */}
          <div id='FeatureDetail_1' className="bg-white py-24 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-primaryBlue">Automate Your Success</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">
                  Personalised Tailoring
                </p>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Optimise your resume effortlessly by uploading an RFQ or Job Description. Our intelligent system analyses the content, identifies essential keywords and requirements, and tailors your CV to increase your chances of landing the job.
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {featuresDetail_1.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-darkGrey">
                        <feature.icon className="h-5 w-5 flex-none text-primaryBlue" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-slateGrey">
                        <p className="flex-auto">{feature.description}</p>

                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          {/* Feature Detail 2 */}
          <div id='FeatureDetail_2' className="bg-white py-24 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-primaryBlue">Streamline Your Application</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">
                  CV Creation from Text
                </p>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Transform your existing resume or plain text into a professional CV effortlessly. Upload your document, and our advanced algorithms will extract and organise the relevant information into a standardised, elegant format.
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {featuresDetail_2.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-darkGrey">
                        <feature.icon className="h-5 w-5 flex-none text-primaryBlue" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-slateGrey">
                        <p className="flex-auto">{feature.description}</p>

                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          {/* Feature Detail 3 */}
          <div id='FeatureDetail_3' className="bg-white py-24 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-primaryBlue">Maximise Efficiency</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">
                  Swift CV Processing
                </p>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Empower your job application process with our rapid CV tool. Convert, personalise, and correct your CV in under 30 seconds, freeing up more time for the important things.</p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {featuresDetail_3.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-darkGrey">
                        <feature.icon className="h-5 w-5 flex-none text-primaryBlue" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-slateGrey">
                        <p className="flex-auto">{feature.description}</p>

                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          {/* Feature Detail 4 */}
          <div id='FeatureDetail_4' className="bg-white py-24 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-primaryBlue">Perfect Your Presentation</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-darkGrey sm:text-4xl">
                  Format Standardisation
                </p>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Achieve a polished and professional look with our format standardisation feature. Our app ensures your CV follows a consistent format, making it more appealing and easier to read for employers.
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {featuresDetail_4.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-darkGrey">
                        <feature.icon className="h-5 w-5 flex-none text-primaryBlue" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-slateGrey">
                        <p className="flex-auto">{feature.description}</p>

                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          {/* Back to top button */}
          <div className="flex items-center justify-center mt-12">
            <a href="#top" className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900 transition-colors duration-300 flex items-center">
              <ArrowUpCircle className="w-5 h-5 mr-2" />
              Back to top
            </a>
          </div>

          {/* FAQ Section */}
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-darkGrey">Frequently asked questions</h2>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full flex-col items-start justify-between text-left text-darkGrey">
                            <div className="w-full flex items-center justify-between">
                              <span className="text-base font-semibold leading-7">{faq.question}</span>
                              <span className="ml-6 h-7 flex items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </div>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12 w-full">
                              <p className="text-base leading-7 text-slateGrey">{faq.answer}</p>
                            </Disclosure.Panel>
                          </Disclosure.Button>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AppShell>
  );
};

export default About;