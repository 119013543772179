import React from 'react';
import AppShell from '../../components/layout/AppShell';
import { Helmet } from 'react-helmet';

const TermsConditions = ({ user }) => {
    return (
        <AppShell currentPage="PageNotFound" loginRequired={false}>
            <Helmet>
                <title>Terms & Conditions</title>
            </Helmet>
            {/* Page-specific content */}
            <main className="min-h-[calc(100vh-25vh)] mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
            </main>
        </AppShell>
    );
};

export default TermsConditions;