import React from 'react';
import AppShell from '../../components/layout/AppShell';
import { Helmet } from 'react-helmet';

const PageNotFound = ({ user }) => {
    return (
        <AppShell currentPage="PageNotFound" loginRequired={false}>
            <Helmet>
                <title>Error - Page not found</title>
            </Helmet>
            {/* Page-specific content */}
            <main className="min-h-[calc(100vh-25vh)] mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
                <p className="text-base font-semibold leading-8 text-primaryBlue">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-darkGrey sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-slateGrey">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10">
                    <a href="/" className="text-sm font-semibold leading-7 text-primaryBlue">
                        <span aria-hidden="true">&larr;</span> Back to home
                    </a>
                </div>
            </main>
        </AppShell>
    );
};

export default PageNotFound;